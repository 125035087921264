import { EventIds } from 'constants/eventIds';
import React, { useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import { log } from 'utils/log';
import { CreatorTrackConfigType } from 'libs/CreatorSession/CreatorSession.types';
import { getGainFromKnobIndex } from 'libs/CreatorSession/CreatorSession.utils';
import { CreatorSessionContextType } from 'contexts/CreatorSessionContext/CreatorSessionContext';
import { useNotificationsContext } from 'contexts/NotificationsContext/NotificationsContext';
import { analytics } from 'utils/analytics';
import { Sample } from 'apollo/sample';
import {
    AddedToCreatorError,
    CreatorSamplesUnavailableError,
} from 'contexts/NotificationsContext/notifications';
import {
    CreatorAction,
    CreatorContextStateType,
    CreatorSampleFetchingStatuses,
    CreatorSamplesMapType,
    CreatorSampleType,
} from '../Creator.types';
import { getSamplesByStatus } from '../helpers/misc.helpers';
import { apolloGetSampleUrl } from '../queries';

export function useFetchSamples(
    state: CreatorContextStateType,
    creatorSession: CreatorSessionContextType,
    creatorDispatch: React.Dispatch<CreatorAction>,
): {
    samplesWithoutStreamUrl: CreatorSamplesMapType;
    samplesWithoutStream: CreatorSamplesMapType;
    samplesInSession: CreatorSamplesMapType;
    samplesReadyToAdd: CreatorSamplesMapType;
} {
    const apolloClient = useApolloClient();

    const { createNotification } = useNotificationsContext();

    const {
        newSamples,
        samplesWithoutStreamUrl,
        samplesWithoutStream,
        samplesReadyToAdd,
        samplesInSession,
        samplesTakenDown,
    } = getSamplesByStatus(state.samples, creatorSession);

    const couldNotAddSampleNotification = useCallback(
        (itemName?: string): void => {
            createNotification(new AddedToCreatorError(itemName));
        },
        [createNotification],
    );

    function removeSample(sample: CreatorSampleType) {
        couldNotAddSampleNotification(sample.name);

        creatorDispatch({
            type: 'removeSamples',
            payload: [sample.guid],
        });
    }

    async function fetchStream(sample: CreatorSampleType): Promise<void> {
        axios
            .get(sample.streamUrl, {
                headers: {
                    'Content-Type': 'application/mpeg', // eslint-disable-line
                },
                responseType: 'blob',
            })
            .then((res) => {
                if (res.status === 200) {
                    creatorDispatch({
                        type: 'setStream',
                        payload: { guid: sample.guid, stream: res.data },
                    });
                } else {
                    removeSample(sample);
                    log.error(
                        `Download Stream response status !== 200`,
                        EventIds.FailedToDownloadStream,
                        new Error('Download Stream response status !== 200'),
                        {
                            guid: sample.guid,
                            streamUrl: sample.streamUrl,
                            status: res.status,
                        },
                    );
                }
            })
            .catch((error: unknown) => {
                removeSample(sample);
            });
    }

    useEffect(() => {
        // FETCH SAMPLE
        newSamples.forEach((sample: CreatorSampleType) => {
            if (!sample.fetching) {
                creatorDispatch({
                    type: 'setFetchStatus',
                    payload: {
                        guid: sample.guid,
                        fetchStatus: CreatorSampleFetchingStatuses.SAMPLE,
                    },
                });

                apolloClient
                    .query({
                        query: Sample,
                        variables: {
                            sampleGuid: sample.guid,
                        },
                    })
                    .then((result) => {
                        creatorDispatch({
                            type: 'updateSample',
                            payload: {
                                guid: sample.guid,
                                sample: result.data.sample,
                            },
                        });
                    })
                    .catch(() => {
                        removeSample(sample);
                    });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newSamples.size]);

    useEffect(() => {
        // FETCH STREAM URL
        samplesWithoutStreamUrl.forEach((sample: CreatorSampleType) => {
            if (sample.fetching !== CreatorSampleFetchingStatuses.STREAM_URL) {
                const { guid } = sample;

                const { bpm } = sample;

                creatorDispatch({
                    type: 'updateSample',
                    payload: {
                        guid: guid,
                        sample: {
                            ...sample,
                            bpm,
                            fetching: false,
                        },
                    },
                });

                apolloGetSampleUrl(
                    sample.guid,
                    apolloClient,
                    creatorDispatch,
                    couldNotAddSampleNotification,
                );
            }
        });
        // Only update when we have new samples
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [samplesWithoutStreamUrl.size]);

    useEffect(() => {
        // FETCH STREAM
        samplesWithoutStream.forEach((sample: CreatorSampleType) => {
            if (sample.fetching !== CreatorSampleFetchingStatuses.STREAM) {
                creatorDispatch({
                    type: 'setFetchStatus',
                    payload: {
                        guid: sample.guid,
                        fetchStatus: CreatorSampleFetchingStatuses.STREAM,
                    },
                });
                fetchStream(sample);
            }
        });
        // Only update when we have new samples
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [samplesWithoutStream.size]);

    useEffect(() => {
        // Wait for all the pending samples are ready to add to the session
        // otherwise we get some janky behaviour in Safari
        if (
            samplesReadyToAdd.size &&
            samplesReadyToAdd.size ===
                state.samples.size - samplesInSession.size
        ) {
            const tracks = [...samplesReadyToAdd.values()].map(
                (sample): CreatorTrackConfigType => {
                    const {
                        bpm,
                        bpmMultiplier,
                        gain,
                        pitchTranspose,
                        stream,
                        isAutoload,
                    } = sample;

                    sample.onAdded();

                    if (!isAutoload) {
                        analytics.trackCreatorUpdated({
                            creatorBpm: state.bpm,
                            creatorKey: `${state.keyRoot} ${state.keyQuality} - ${state.keySign}`,
                            deletedCreatorSession: false,
                            numberSamplesInCreator: state.samples.size || 0,
                            sampleAdded: true,
                            sampleRemoved: false,
                            creatorSessionId: state.instrumentationSessionId,
                            samplePackId: sample.pack?.guid,
                            samplePackName: sample.pack?.name,
                            assetArtist: sample.pack?.artist?.guid,
                            assetBpm: sample.bpm,
                            assetId: sample.guid,
                            assetKey: sample.key?.code,
                            assetLength: sample.duration || undefined,
                            assetName: sample.name,
                            assetGenre: sample.pack?.mainGenre?.code,
                            assetLabel: sample.pack?.label?.guid || undefined,
                            assetLoopType: sample.sampleType,
                        });
                    }

                    return {
                        ...sample,
                        name: sample?.name || '',
                        duration: sample.duration || null,
                        key: sample.key || null,
                        id: sample.guid,
                        bpm,
                        bpmMultiplier,
                        gain: getGainFromKnobIndex(gain),
                        stream: stream as Response,
                        pitchTranspose,
                        isAutoload,
                    };
                },
            );

            creatorSession.addTracks(tracks);
        }
        // Only update when we have new samples
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [samplesReadyToAdd.size]);

    useEffect(() => {
        if (samplesTakenDown.size) {
            const guids: string[] = [];

            samplesTakenDown.forEach(({ guid }) => {
                guids.push(guid);
            });

            creatorDispatch({
                type: 'removeSamples',
                payload: guids,
            });

            createNotification(new CreatorSamplesUnavailableError());
        }
    }, [samplesTakenDown, createNotification, creatorDispatch]);

    return {
        samplesWithoutStreamUrl,
        samplesWithoutStream,
        samplesReadyToAdd,
        samplesInSession,
    };
}
