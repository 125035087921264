import { CreatorBrand } from 'constants/brands';
import React from 'react';
import { Trans } from '@lingui/macro';
import { Shrinkable, NoShrink } from './components/Ellipsis';
import { NotificationBase } from './types';

export class AddedToFavoritesSuccess extends NotificationBase {
    itemName?: string;

    constructor(itemName?: string, id?: string) {
        super(id);
        this.variant = 'success';
        this.itemName = itemName;
    }

    getMessage = (isMobile = false) =>
        isMobile || !this.itemName ? (
            <Trans>
                <NoShrink>Added to Favorites</NoShrink>
            </Trans>
        ) : (
            <Trans>
                <Shrinkable>{this.itemName}</Shrinkable>
                <NoShrink>added to Favorites</NoShrink>
            </Trans>
        );
}

export class AddedToFavoritesError extends NotificationBase {
    itemName?: string;

    constructor(itemName?: string, id?: string) {
        super(id);
        this.variant = 'danger';
        this.itemName = itemName;
    }

    getMessage = (isMobile = false) =>
        isMobile || !this.itemName ? (
            <Trans>
                <NoShrink>Failed to add to Favorites</NoShrink>
            </Trans>
        ) : (
            <Trans>
                <NoShrink>Failed to add</NoShrink>
                <Shrinkable>{this.itemName}</Shrinkable>
                <NoShrink>to Favorites</NoShrink>
            </Trans>
        );
}

export class RemovedFromFavoritesSuccess extends NotificationBase {
    itemName?: string;

    constructor(itemName?: string, id?: string) {
        super(id);
        this.variant = 'success';
        this.itemName = itemName;
    }

    getMessage = (isMobile = false) =>
        isMobile || !this.itemName ? (
            <Trans>
                <NoShrink>Removed from Favorites</NoShrink>
            </Trans>
        ) : (
            <Trans>
                <Shrinkable>{this.itemName}</Shrinkable>
                <NoShrink>removed from Favorites</NoShrink>
            </Trans>
        );
}

export class RemovedFromFavoritesError extends NotificationBase {
    itemName?: string;

    constructor(itemName?: string, id?: string) {
        super(id);
        this.variant = 'danger';
        this.itemName = itemName;
    }

    getMessage = (isMobile = false) =>
        isMobile || !this.itemName ? (
            <Trans>
                <NoShrink>Failed to remove from Favorites</NoShrink>
            </Trans>
        ) : (
            <Trans>
                <NoShrink>Failed to remove</NoShrink>
                <Shrinkable> {this.itemName} </Shrinkable>
                <NoShrink>from Favorites</NoShrink>
            </Trans>
        );
}

export class AddedToCreatorSuccess extends NotificationBase {
    itemName?: string;

    constructor(itemName?: string, id?: string) {
        super(id);
        this.variant = 'success';
        this.itemName = itemName;
    }

    getMessage = (isMobile = false) =>
        isMobile || !this.itemName ? (
            <Trans>
                <NoShrink>Added to {CreatorBrand}</NoShrink>
            </Trans>
        ) : (
            <Trans>
                <Shrinkable>{this.itemName}</Shrinkable>
                <NoShrink>added to {CreatorBrand}</NoShrink>
            </Trans>
        );
}

export class RemovedAllFromCreatorSuccess extends NotificationBase {
    constructor(id?: string) {
        super(id);
        this.variant = 'success';
    }

    getMessage = () => (
        <Trans>
            <NoShrink>Deleted from {CreatorBrand}</NoShrink>
        </Trans>
    );
}

export class RemovedFromCreatorSuccess extends NotificationBase {
    itemName?: string;

    constructor(itemName?: string, id?: string) {
        super(id);
        this.variant = 'success';
        this.itemName = itemName;
    }

    getMessage = (isMobile = false) =>
        isMobile || !this.itemName ? (
            <Trans>
                <NoShrink>Removed from {CreatorBrand}</NoShrink>
            </Trans>
        ) : (
            <Trans>
                <Shrinkable>{this.itemName}</Shrinkable>
                <NoShrink>removed from {CreatorBrand}</NoShrink>
            </Trans>
        );
}

export class CreatorFullError extends NotificationBase {
    constructor(id?: string) {
        super(id);
        this.variant = 'danger';
    }

    getMessage = () => (
        <Trans>
            <NoShrink>
                {CreatorBrand} is full. Remove sounds to continue
            </NoShrink>
        </Trans>
    );
}

export class CreatorSamplesUnavailableError extends NotificationBase {
    constructor(id?: string) {
        super(id);
        this.variant = 'danger';
    }

    getMessage = () => (
        <Trans>
            <NoShrink>Some samples in your session are unavailable</NoShrink>
        </Trans>
    );
}

export class AddedToCreatorError extends NotificationBase {
    itemName?: string;

    constructor(itemName?: string, id?: string) {
        super(id);
        this.variant = 'danger';
        this.itemName = itemName;
    }

    getMessage = (isMobile = false) =>
        isMobile || !this.itemName ? (
            <Trans>
                <NoShrink>Failed to add to {CreatorBrand}</NoShrink>
            </Trans>
        ) : (
            <Trans>
                <NoShrink>Failed to add</NoShrink>
                <Shrinkable>{this.itemName}</Shrinkable>
                <NoShrink>to {CreatorBrand}</NoShrink>
            </Trans>
        );
}

export class CreatorMixdownError extends NotificationBase {
    constructor(id?: string) {
        super(id);
        this.variant = 'danger';
    }

    getMessage = () => (
        <Trans>
            <NoShrink>There was an error generating your mixdown</NoShrink>
        </Trans>
    );
}

export class PlayerFailedToPlayError extends NotificationBase {
    constructor(id?: string) {
        super(id);
        this.variant = 'danger';
    }

    getMessage = () => (
        <Trans>
            <NoShrink>Failed to play sound</NoShrink>
        </Trans>
    );
}

export class PreparePurchaseSuccess extends NotificationBase {
    constructor(id?: string) {
        super(id);
        this.variant = 'progress';
        this.timeout = undefined;
    }
    getMessage = () => (
        <Trans>
            <NoShrink>Preparing</NoShrink>
        </Trans>
    );
}

export class AddedToDownloadsError extends NotificationBase {
    constructor(id?: string) {
        super(id);
        this.variant = 'danger';
    }

    getMessage = () => (
        <Trans>
            <NoShrink>Failed to add to Downloads</NoShrink>
        </Trans>
    );
}

export class PurchaseApiError extends NotificationBase {
    constructor(id?: string) {
        super(id);
        this.variant = 'danger';
    }

    getMessage = () => (
        <Trans>
            <NoShrink>There was a problem with your session</NoShrink>
        </Trans>
    );
}

export class AddedMultipleToDownloadsSuccess extends NotificationBase {
    itemCount: number;

    constructor(itemCount: number, id?: string) {
        super(id);
        this.variant = 'success';
        this.itemCount = itemCount;
    }

    getMessage = (isMobile = false) =>
        isMobile || !this.itemCount ? (
            <Trans>
                <NoShrink>Added to Downloads</NoShrink>
            </Trans>
        ) : (
            <Trans>
                <NoShrink>{this.itemCount} samples added to Downloads</NoShrink>
            </Trans>
        );
}

export class AddedToDownloadsSuccess extends NotificationBase {
    itemName?: string;

    constructor(itemName?: string, id?: string) {
        super(id);
        this.variant = 'success';
        this.itemName = itemName;
    }

    getMessage = (isMobile = false) =>
        isMobile || !this.itemName ? (
            <Trans>
                <NoShrink>Added to Downloads</NoShrink>
            </Trans>
        ) : (
            <Trans>
                <Shrinkable>{this.itemName}</Shrinkable>
                <NoShrink>added to Downloads</NoShrink>
            </Trans>
        );
}

export class AddedSingleSampleToCollectionSuccess extends NotificationBase {
    collectionName?: string;
    sampleName?: string;

    constructor(collectionName?: string, sampleName?: string, id?: string) {
        super(id);
        this.variant = 'success';
        this.collectionName = collectionName;
        this.sampleName = sampleName;
    }

    getMessage = (isMobile = false) => {
        if (!this.collectionName) {
            return (
                <Trans>
                    <NoShrink>Added to collection</NoShrink>
                </Trans>
            );
        }

        if (isMobile || !this.sampleName) {
            return (
                <Trans>
                    <NoShrink>Added to</NoShrink>
                    <Shrinkable>{this.collectionName}</Shrinkable>
                </Trans>
            );
        }

        return (
            <Trans>
                <Shrinkable>{this.sampleName}</Shrinkable>
                <NoShrink>added to</NoShrink>
                <Shrinkable>{this.collectionName}</Shrinkable>
            </Trans>
        );
    };
}

export class AddedMultipleSamplesToCollectionSuccess extends NotificationBase {
    collectionName?: string;
    samplesCount?: number;

    constructor(collectionName?: string, samplesCount?: number, id?: string) {
        super(id);
        this.variant = 'success';
        this.collectionName = collectionName;
        this.samplesCount = samplesCount;
    }

    getMessage = (isMobile = false) => {
        if (!this.collectionName) {
            return (
                <Trans>
                    <NoShrink>Added to collection</NoShrink>
                </Trans>
            );
        }

        if (isMobile || !this.samplesCount) {
            return (
                <Trans>
                    <NoShrink>Added to</NoShrink>
                    <Shrinkable>{this.collectionName}</Shrinkable>
                </Trans>
            );
        }

        return (
            <Trans>
                <NoShrink>{this.samplesCount} samples added to</NoShrink>
                <Shrinkable>{this.collectionName}</Shrinkable>
            </Trans>
        );
    };
}

export class AddedSingleSampleToCollectionError extends NotificationBase {
    collectionName?: string;
    sampleName?: string;

    constructor(collectionName?: string, sampleName?: string, id?: string) {
        super(id);
        this.variant = 'danger';
        this.collectionName = collectionName;
        this.sampleName = sampleName;
    }

    getMessage = (isMobile = false) => {
        if (!this.collectionName) {
            return (
                <Trans>
                    <NoShrink>Failed to add to collection</NoShrink>
                </Trans>
            );
        }

        if (isMobile || !this.sampleName) {
            return (
                <Trans>
                    <NoShrink>Failed to add to</NoShrink>
                    <Shrinkable>{this.collectionName}</Shrinkable>
                </Trans>
            );
        }

        return (
            <Trans>
                <NoShrink>Failed to add</NoShrink>
                <Shrinkable>{this.sampleName}</Shrinkable>
                <NoShrink>to</NoShrink>
                <Shrinkable>{this.collectionName}</Shrinkable>
            </Trans>
        );
    };
}

export class AddedMultipleSamplesToCollectionError extends NotificationBase {
    collectionName?: string;
    samplesCount?: number;

    constructor(collectionName?: string, samplesCount?: number, id?: string) {
        super(id);
        this.variant = 'danger';
        this.collectionName = collectionName;
        this.samplesCount = samplesCount;
    }

    getMessage = (isMobile = false) => {
        if (!this.collectionName) {
            return (
                <Trans>
                    <NoShrink>Failed to add to collection</NoShrink>
                </Trans>
            );
        }

        if (isMobile || !this.samplesCount) {
            return (
                <Trans>
                    <NoShrink>Failed to add to</NoShrink>
                    <Shrinkable>{this.collectionName}</Shrinkable>
                </Trans>
            );
        }

        return (
            <Trans>
                <NoShrink>
                    Failed to add {this.samplesCount} samples to
                </NoShrink>
                <Shrinkable>{this.collectionName}</Shrinkable>
            </Trans>
        );
    };
}

export class CollectionRenamedSuccess extends NotificationBase {
    collectionName: string;

    constructor(collectionName: string, id?: string) {
        super(id);
        this.variant = 'success';
        this.collectionName = collectionName;
    }

    getMessage = () =>
        !this.collectionName ? (
            <Trans>
                <NoShrink>Collection renamed</NoShrink>
            </Trans>
        ) : (
            <Trans>
                <NoShrink>Collection</NoShrink>
                <Shrinkable>{this.collectionName}</Shrinkable>
                <NoShrink>renamed</NoShrink>
            </Trans>
        );
}

export class CollectionRenamedError extends NotificationBase {
    collectionName: string;

    constructor(collectionName: string, id?: string) {
        super(id);
        this.variant = 'danger';
        this.collectionName = collectionName;
    }

    getMessage = () =>
        !this.collectionName ? (
            <Trans>
                <NoShrink>Failed to rename collection</NoShrink>
            </Trans>
        ) : (
            <Trans>
                <NoShrink>Failed to rename</NoShrink>
                <Shrinkable>{this.collectionName}</Shrinkable>
            </Trans>
        );
}

export class CollectionCreatedSuccess extends NotificationBase {
    collectionName: string;

    constructor(collectionName: string, id?: string) {
        super(id);
        this.variant = 'success';
        this.collectionName = collectionName;
    }

    getMessage = () =>
        !this.collectionName ? (
            <Trans>
                <NoShrink>Collection created</NoShrink>
            </Trans>
        ) : (
            <Trans>
                <NoShrink>Collection</NoShrink>
                <Shrinkable>{this.collectionName}</Shrinkable>
                <NoShrink>created</NoShrink>
            </Trans>
        );
}

export class CollectionCreatedError extends NotificationBase {
    collectionName: string;

    constructor(collectionName: string, id?: string) {
        super(id);
        this.variant = 'danger';
        this.collectionName = collectionName;
    }

    getMessage = (isMobile = false) =>
        isMobile || !this.collectionName ? (
            <Trans>
                <NoShrink>Failed to create collection</NoShrink>
            </Trans>
        ) : (
            <Trans>
                <NoShrink>Failed to create</NoShrink>
                <Shrinkable>{this.collectionName}</Shrinkable>
            </Trans>
        );
}

export class RemoveSingleSampleFromCollectionSuccess extends NotificationBase {
    collectionName: string;
    sampleName?: string;

    constructor(collectionName: string, sampleName?: string, id?: string) {
        super(id);
        this.variant = 'success';
        this.collectionName = collectionName;
        this.sampleName = sampleName;
    }

    getMessage = (isMobile = false) => {
        if (!this.collectionName) {
            <Trans>
                <NoShrink>Removed from collection</NoShrink>
            </Trans>;
        }

        if (isMobile || !this.sampleName) {
            return (
                <Trans>
                    <NoShrink>Removed from</NoShrink>
                    <Shrinkable>{this.collectionName}</Shrinkable>
                </Trans>
            );
        }

        return (
            <Trans>
                <Shrinkable>{this.sampleName}</Shrinkable>
                <NoShrink>removed from</NoShrink>
                <Shrinkable>{this.collectionName}</Shrinkable>
            </Trans>
        );
    };
}

export class RemoveMultipleSamplesFromCollectionSuccess extends NotificationBase {
    collectionName: string;
    sampleCount: number;

    constructor(collectionName: string, sampleCount: number, id?: string) {
        super(id);
        this.variant = 'success';
        this.collectionName = collectionName;
        this.sampleCount = sampleCount;
    }

    getMessage = (isMobile = false) => {
        if (!this.collectionName) {
            return (
                <Trans>
                    <NoShrink>Removed from collection</NoShrink>
                </Trans>
            );
        }

        if (isMobile || !this.sampleCount) {
            return (
                <Trans>
                    <NoShrink>Removed from</NoShrink>
                    <Shrinkable>{this.collectionName}</Shrinkable>
                </Trans>
            );
        }

        return (
            <Trans>
                <NoShrink>{this.sampleCount} samples removed from</NoShrink>
                <Shrinkable>{this.collectionName}</Shrinkable>
            </Trans>
        );
    };
}

export class RemoveSingleSampleFromCollectionError extends NotificationBase {
    collectionName: string;
    sampleName?: string;

    constructor(collectionName: string, sampleName?: string, id?: string) {
        super(id);
        this.variant = 'danger';
        this.collectionName = collectionName;
        this.sampleName = sampleName;
    }

    getMessage = (isMobile = false) => {
        if (!this.collectionName) {
            return (
                <Trans>
                    <NoShrink>Failed to remove from collection</NoShrink>
                </Trans>
            );
        }

        if (isMobile || !this.sampleName) {
            return (
                <Trans>
                    <NoShrink>Failed to remove from</NoShrink>
                    <Shrinkable>{this.collectionName}</Shrinkable>
                </Trans>
            );
        }

        return (
            <Trans>
                <NoShrink>Failed to remove</NoShrink>
                <Shrinkable>{this.sampleName}</Shrinkable>
                <NoShrink>from</NoShrink>
                <Shrinkable>{this.collectionName}</Shrinkable>
            </Trans>
        );
    };
}

export class RemoveMultipleSamplesFromCollectionError extends NotificationBase {
    collectionName: string;
    sampleCount: number;

    constructor(collectionName: string, sampleCount: number, id?: string) {
        super(id);
        this.variant = 'danger';
        this.collectionName = collectionName;
        this.sampleCount = sampleCount;
    }

    getMessage = (isMobile = false) => {
        if (!this.collectionName) {
            return (
                <Trans>
                    <NoShrink>Failed to remove from collection</NoShrink>
                </Trans>
            );
        }

        if (isMobile || !this.sampleCount) {
            return (
                <Trans>
                    <NoShrink>Failed to remove from</NoShrink>
                    <Shrinkable>{this.collectionName}</Shrinkable>
                </Trans>
            );
        }

        return (
            <Trans>
                <NoShrink>
                    Failed to remove {this.sampleCount} samples from
                </NoShrink>
                <Shrinkable>{this.collectionName}</Shrinkable>
            </Trans>
        );
    };
}

export class DeleteUserRecentSearchError extends NotificationBase {
    constructor(id?: string) {
        super(id);
        this.variant = 'danger';
    }

    getMessage = () => (
        <Trans>
            <NoShrink>Failed to remove</NoShrink>
        </Trans>
    );
}
