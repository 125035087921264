import { gql } from '@apollo/client';

export const ARTIST_BASE_FRAGMENT = gql`
    fragment ArtistBaseFragment on Artist {
        guid
        slug
        name
        isExclusive
    }
`;

export const ARTIST_EXCERPT_FRAGMENT = gql`
    fragment ArtistExcerptFragment on Artist {
        ...ArtistBaseFragment
        artworkUrl
    }
    ${ARTIST_BASE_FRAGMENT}
`;

export const ARTIST_DETAIL_FRAGMENT = gql`
    fragment ArtistDetailFragment on Artist {
        ...ArtistExcerptFragment
        description
        artworkUrl
        portraitUrl
        coverUrl
        isExclusive
        sampleCount
        packCount
    }
    ${ARTIST_EXCERPT_FRAGMENT}
`;
