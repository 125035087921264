import React from 'react';
import PageMetadata from 'components/PageMetadata';
import { useSeoMetaData } from 'hooks/useSeoMetaData';

export const DynamicMetadata = ({
    titleFallback,
    descriptionFallback,
    imageUrl,
    withWildcard,
    withGoogleSearch,
}: {
    titleFallback: string;
    descriptionFallback: string;
    imageUrl?: string;
    withWildcard?: boolean;
    withGoogleSearch?: boolean;
}) => {
    const { loading, seoMetaData } = useSeoMetaData({ withWildcard });

    if (loading) {
        return null;
    }

    return (
        <PageMetadata
            title={seoMetaData?.title ?? titleFallback}
            description={seoMetaData?.description ?? descriptionFallback}
            imageUrl={imageUrl}
            withGoogleSearch={withGoogleSearch}
        />
    );
};
