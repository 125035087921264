import { setContext } from '@apollo/client/link/context';
import { getAnalyticsPlatformName } from 'helpers/executionEnvironment';
import { getXLandrAnalytics, AnalyticsApplication } from '@landr/core';
import { Languages } from 'types/enums/languages';
import { i18n } from 'utils/setupTranslations';
import { featureFlagService } from 'helpers/featureFlagsService/feature-flag-service';
import { auth } from 'utils/auth/auth';
import { v4 as uuidv4 } from 'uuid';

const API_LANGUAGES: { [k in Languages]: string } = {
    [Languages.EN]: 'en-US',
    [Languages.FR]: Languages.FR,
    [Languages.ES]: Languages.ES,
};

export const contextLink = setContext(async (_, prevContext) => {
    const { headers: previousHeaders } = prevContext;

    const token = await auth.getAccessToken();

    const authorization = token
        ? { authorization: `Bearer ${token}` }
        : undefined;

    return {
        ...prevContext,
        headers: {
            ...previousHeaders,
            'X-LANDR-ANALYTICS': JSON.stringify(
                getXLandrAnalytics(
                    AnalyticsApplication.Samples,
                    window,
                    getAnalyticsPlatformName(),
                ),
            ),
            'X-API-Languages': API_LANGUAGES[i18n.locale as Languages],
            'X-LANDR-FEATUREFLAGS': JSON.stringify(featureFlagService.get()),
            'X-CorrelationId': uuidv4(),
            ...authorization,
        },
    };
});
