import React from 'react';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { useQuery } from '@apollo/client';
import { ArtistBySlug } from 'apollo/artist/artist.gql';
import { getSanitizeSlug } from 'helpers/getSanitizeSlug';
import {
    ArtistBySlugQuery,
    ArtistBySlugQueryVariables,
} from 'types/generated/graphql';
import { DynamicMetadata } from '../DynamicMetadata/DynamicMetadata';

export const useArtistDetails = () => {
    const { slug } = useParams<{ slug: string }>();
    const { data } = useQuery<ArtistBySlugQuery, ArtistBySlugQueryVariables>(
        ArtistBySlug,
        {
            variables: { slug: getSanitizeSlug(slug) },
        },
    );

    if (!data?.artistBySlug?.name) {
        return {};
    }

    const { name, artworkUrl } = data.artistBySlug;

    return {
        title: t({
            message: '{name} Sample Pack',
            values: { name },
        }),
        description: t({
            message:
                '{name} samples – one shots, loops, phrases, melodies, chords, licks and more — for you to download and use in your productions.',
            values: { name },
        }),
        imageUrl: artworkUrl,
    };
};

export const ArtistDetails = () => {
    const { title, description, imageUrl } = useArtistDetails();

    if (!title || !description) {
        return null;
    }

    return (
        <DynamicMetadata
            imageUrl={imageUrl}
            titleFallback={title}
            descriptionFallback={description}
        />
    );
};
