import getStaticSeo from 'constants/staticSeo';
import { CreatorBrand, LandrBrand } from 'constants/brands';
import { LanguageToLocale, Languages, RoutePaths } from 'types/enums';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { AnalyticsLanguage } from '@landr/core';
import { useToLocalizedPath } from 'hooks/useToLocalizedPath/useToLocalizedPath';
import { useLocation, useMatch } from 'react-router-dom';
import { CONFIG } from 'utils';
import { analytics } from 'utils/analytics';
import { i18n } from 'utils/setupTranslations';
import { useErrorBoundaryContext } from 'contexts/ErrorBoundaryContext/ErrorBoundaryContext';
import { useAlternateLinks } from './useAlternateLinks';

export interface PageMetadataProps {
    title?: string;
    description?: string;
    imageUrl?: string;
    withGoogleSearch?: boolean;
}

const PageMetadata: React.FC<PageMetadataProps> = ({
    title,
    description,
    imageUrl,
    withGoogleSearch,
}) => {
    const location = useLocation();
    const { error } = useErrorBoundaryContext();
    const staticSeo = getStaticSeo();
    const toLocalizedPath = useToLocalizedPath();

    const { localeLinks, xDefaultLink } = useAlternateLinks();

    const isBeatMakerHomePage = useMatch(
        toLocalizedPath(`/${RoutePaths.CreatorBeatMaker}`),
    );

    const isBeatMakerSharedPage = useMatch(
        toLocalizedPath(`/${RoutePaths.CreatorBeatMaker}/:guid`),
    );

    const isCreatorBrandTitle = isBeatMakerHomePage && !isBeatMakerSharedPage;
    const brandTitle = isCreatorBrandTitle
        ? `${CreatorBrand} by ${LandrBrand}`
        : `${LandrBrand} Samples`;

    /**
     * On FF cleanup, I would suggest to move these default back to `PageMetadata.defaultProps`
     */
    const seoTitle = `${title || staticSeo.default.title} | ${brandTitle}`;
    const seoDescription = description || staticSeo.default.description;
    const seoImageUrl = imageUrl || staticSeo.default.imageUrl;
    const ogLocale = LanguageToLocale[i18n.locale as Languages];

    const handleLocationChange = useCallback(() => {
        // Send analytics to segment on route changes
        analytics.page(i18n.locale as AnalyticsLanguage);
    }, [location]);

    return (
        <Helmet onChangeClientState={handleLocationChange}>
            {/* Basic metadata */}
            <title>{seoTitle}</title>
            <meta name="description" content={seoDescription} />
            {error?.variant === 'page' && (
                <meta name="prerender-status-code" content="404" />
            )}
            <link rel="canonical" href={window.location.href} />
            {xDefaultLink}
            {localeLinks}
            {/* OpenGraph tags */}
            <meta
                property="og:url"
                content={window.location.origin + location.pathname}
            />
            <meta property="og:title" content={seoTitle} />
            <meta property="og:description" content={seoDescription} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={seoImageUrl} />
            <meta property="og:locale" content={ogLocale} />
            <meta property="og:site_name" content="LANDR Samples" />
            {/* Twitter Card tags */}
            <meta name="twitter:title" content={seoTitle} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@LANDR_music" />
            <meta name="twitter:description" content={seoDescription} />
            <meta name="twitter:image" content={seoImageUrl} />
            {/* Corporate Contacts Information for Google Search */}
            <script type="application/ld+json">
                {`                
                    {
                        "@context" : "http://schema.org",
                        "@type": "WebSite",
                        "name" : "LANDR",
                        "image": "${
                            CONFIG.VITE_GUESTSITE_URL
                        }/img/logo-landr.png",
                        "url": "https://samples.landr.com/",                
                        "sameAs" : [
                            "https://twitter.com/LANDR_music",
                            "https://www.facebook.com/LANDRmusic",
                            "https://www.youtube.com/user/LANDRmastering",
                            "https://www.linkedin.com/company/mixgeni-us"
                        ],
                        ${
                            withGoogleSearch
                                ? `"potentialAction": {
                                "@type": "SearchAction",
                                "target": {
                                    "@type": "EntryPoint",
                                    "urlTemplate": "https://samples.landr.com/browse?textsearch={search_query_input}"
                                },
                                "query-input": "required name=search_query_input"
                            },`
                                : ''
                        }
                        "publisher": {                        
                            "@type": "Organization",
                            "name": "LANDR",
                            "url": "${CONFIG.VITE_GUESTSITE_URL}",
                            "telephone": "+1 514-840-9700",
                            "email": "mailto:support@landr.com",
                            "image": "${
                                CONFIG.VITE_GUESTSITE_URL
                            }/img/logo-landr.png",
                            "logo": "${
                                CONFIG.VITE_GUESTSITE_URL
                            }/img/logo-landr.png",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Montreal",
                                "addressRegion": "QC",
                                "addressCountry": "CA",
                                "postalCode":"H3B 4L4",
                                "streetAddress": "1001 Boulevard Robert-Bourassa Suite 2100"
                            }
                        }
                    }
                `}
            </script>
        </Helmet>
    );
};

export default React.memo(PageMetadata);
