import { gql } from '@apollo/client';
import { ARTIST_BASE_FRAGMENT } from 'apollo/artist/artist.fragment';
import { GENRE_FRAGMENT, SFX_FRAGMENT } from 'apollo/filters/filters.fragment';
import { LABEL_BASE_FRAGMENT } from 'apollo/label/label.fragment';

export const PACK_BASE_FRAGMENT = gql`
    fragment PackBaseFragment on Pack {
        artworkUrl
        guid
        isExclusive
        isFree
        name
        packState
        slug
        type
        artist {
            ...ArtistBaseFragment
        }
        label {
            ...LabelBaseFragment
        }
    }
    ${ARTIST_BASE_FRAGMENT}
    ${LABEL_BASE_FRAGMENT}
`;

export const PACK_EXCERPT_FRAGMENT = gql`
    fragment PackExcerptFragment on Pack {
        ...PackBaseFragment
        isDownloaded
        isFavorited
    }
    ${PACK_BASE_FRAGMENT}
`;

export const PACK_DETAIL_FRAGMENT = gql`
    fragment PackDetailFragment on Pack {
        ...PackExcerptFragment
        backgroundColor
        creatorSession
        description
        foregroundColor
        premiumCodes
        sampleCount
        genres {
            ...GenreFragment
        }
        mainGenre {
            ...GenreFragment
        }
        sfxs {
            ...SfxFragment
        }
    }
    ${PACK_EXCERPT_FRAGMENT}
    ${GENRE_FRAGMENT}
    ${SFX_FRAGMENT}
`;
