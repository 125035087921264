import { useQuery } from '@apollo/client';
import { GetFilters } from 'apollo/filters/filters.gql';
import { FiltersQuery, FilterCategories } from 'types/generated/graphql';

export const defaultApiFilters: FilterCategories = {
    genres: [],
    instruments: [],
    sampleTypes: [],
    sfxs: [],
    tags: [],
};

const useApiFilters = (): {
    apiFilters: FilterCategories;
    loading: boolean;
} => {
    const { data, loading } = useQuery<FiltersQuery>(GetFilters, {
        notifyOnNetworkStatusChange: true,
    });

    return {
        apiFilters: data?.filters || defaultApiFilters,
        loading,
    };
};

export default useApiFilters;
