import { NetworkErrorWhiteList } from 'constants/networkErrorWhiteList';
import { useEffect } from 'react';
import {
    DocumentNode,
    LazyQueryHookOptions,
    useLazyQuery,
} from '@apollo/client';
import { useAuthentication } from 'components/AuthenticationProvider/AuthenticationProvider';

export type DoQuery = ReturnType<typeof useLazyQuery>[0];

// Run an Apollo query only once the user becomes authenticated
export const useQueryWhenAuthenticated = <T = any>({
    query,
    options,
}: {
    query: DocumentNode;
    options?: LazyQueryHookOptions;
}) => {
    const { isAuthenticated } = useAuthentication();

    const [doQuery, { data, loading }] = useLazyQuery<T>(query, {
        fetchPolicy: 'network-only',
        // Otherwise onError won't run on re-fetch
        notifyOnNetworkStatusChange: true,
        ...options,
    });

    useEffect(() => {
        const abortController = new AbortController();

        if (isAuthenticated) {
            doQuery({
                context: {
                    fetchOptions: {
                        signal: abortController.signal,
                    },
                },
            });
        }

        return () => {
            abortController.abort(NetworkErrorWhiteList.AbortController);
        };
    }, [isAuthenticated, doQuery]);

    return { data, doQuery, loading };
};
