import { PutUserMilestones } from 'apollo/milestones/milestones.hooks';
import { useCallback } from 'react';

const usePersistedMilestones = ({
    putUserMilestones,
    persistedMilestones = [],
}: {
    putUserMilestones: PutUserMilestones;
    persistedMilestones?: string[];
}) => {
    const updatePersistedMilestone = (milestone: string) => {
        putUserMilestones({
            variables: {
                milestones: [...new Set([...persistedMilestones, milestone])],
            },
        });
    };

    const updateAllPersistedMilestones = useCallback(
        (milestones: string[]): void => {
            putUserMilestones({
                variables: {
                    milestones: [
                        ...new Set([...persistedMilestones, ...milestones]),
                    ],
                },
            });
        },
        [persistedMilestones, putUserMilestones],
    );

    return {
        updateAllPersistedMilestones,
        updatePersistedMilestone,
    };
};

export default usePersistedMilestones;
