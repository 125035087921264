// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import ResizeObserver from 'resize-observer-polyfill';
import hasOwn from 'object.hasown';
import queueMicrotask from 'queue-microtask';

function queueMicrotaskPolyfill() {
    if (typeof window.queueMicrotask !== 'function') {
        window.queueMicrotask = queueMicrotask;
    }
}

function entriesPolyfill() {
    if (!Object.entries) {
        Object.entries = function (obj: { [x: string]: any }) {
            let ownProps = Object.keys(obj),
                i = ownProps.length,
                resArray = new Array(i);

            while (i--) {
                resArray[i] = [ownProps[i], obj[ownProps[i]]];
            }

            return resArray;
        };
    }
}

function loadFromEntriesPolyfill() {
    // https://github.com/feross/fromentries/blob/master/index.js
    if (!Object.fromEntries) {
        Object.defineProperty(Object, 'fromEntries', {
            value<T = any>(entries: Iterable<readonly [string | number, T]>) {
                if (!entries || !entries[Symbol.iterator]) {
                    throw new Error(
                        'Object.fromEntries() requires a single iterable argument',
                    );
                }

                return [...entries].reduce<Record<string | number, T>>(
                    (obj, [key, val]) => {
                        obj[key] = val;
                        return obj;
                    },
                    {},
                );
            },
        });
    }
}

function flatPolyfill() {
    // https://github.com/jonathantneal/array-flat-polyfill/blob/master/src/polyfill-flat.js
    if (!Array.prototype.flat) {
        Object.defineProperty(Array.prototype, 'flat', {
            configurable: true,
            value: function flat() {
                const depth = isNaN(arguments[0]) ? 1 : Number(arguments[0]);

                return depth
                    ? Array.prototype.reduce.call(
                          this,
                          function (acc, cur) {
                              if (Array.isArray(cur)) {
                                  acc.push.apply(
                                      acc,
                                      flat.call(cur, depth - 1),
                                  );
                              } else {
                                  acc.push(cur);
                              }

                              return acc;
                          },
                          [],
                      )
                    : Array.prototype.slice.call(this);
            },
            writable: true,
        });
    }
}

const resizeObserver = async () => {
    if ('ResizeObserver' in window === false) {
        // Loads polyfill only if required.
        (window as typeof globalThis).ResizeObserver = ResizeObserver;
    }
};

const hasOwnPolyfill = () => {
    if (!Object.hasOwn) {
        hasOwn.shim();
    }
};

export default function setupPolyfills() {
    hasOwnPolyfill();
    entriesPolyfill();
    loadFromEntriesPolyfill();
    flatPolyfill();
    resizeObserver();
    queueMicrotaskPolyfill();
}
