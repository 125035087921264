import { PaginatedDownloadedSamples } from 'apollo/sample/sample.gql';
import { PaginatedDownloadedPacksAndRepacks } from 'apollo/packAndRepack/packAndRepack.gql';

const downloadsQueries = {
    samples: {
        query: PaginatedDownloadedSamples,
        pathToItems: 'paginatedDownloadedSamples',
        pathToTotalItems: 'totalSampleRecords',
    },
    packs: {
        query: PaginatedDownloadedPacksAndRepacks,
        pathToItems: 'paginatedDownloadedPacksAndRepacksV2',
        pathToTotalItems: 'totalRecords',
    },
};

export default downloadsQueries;
