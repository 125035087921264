import { gql } from '@apollo/client';
import {
    INSTRUMENT_FRAGMENT,
    SFX_FRAGMENT,
    TAG_FRAGMENT,
} from 'apollo/filters/filters.fragment';
import { PACK_BASE_FRAGMENT } from 'apollo/pack/pack.fragment';

export const SAMPLE_KEY_FRAGMENT = gql`
    fragment SampleKeyFragment on SampleKey {
        code
        root
        quality
    }
`;

export const SAMPLE_FRAGMENT = gql`
    fragment SampleFragment on Sample {
        guid
        name
        bpm
        credits
        duration
        isDownloaded
        isFavorited
        isRecommendationFiller
        sampleState
        sampleType
        waveformUrl
        instruments {
            ...InstrumentFragment
        }
        key {
            ...SampleKeyFragment
        }
        tags {
            ...TagFragment
        }
        sfxs {
            ...SfxFragment
        }
        pack {
            ...PackBaseFragment
        }
    }
    ${INSTRUMENT_FRAGMENT}
    ${SAMPLE_KEY_FRAGMENT}
    ${PACK_BASE_FRAGMENT}
    ${SFX_FRAGMENT}
    ${TAG_FRAGMENT}
`;
