import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GetSeo } from 'apollo/seo';
import { SeoType } from 'types/seoType';
import {
    GetSeoByPathQuery,
    GetSeoByPathQueryVariables,
} from 'types/generated/graphql';
import { i18n } from 'utils';

export const toSeoPathnameSyntax = ({
    pathname,
    withWildcard = false,
}: {
    pathname?: string;
    withWildcard?: boolean;
}) => {
    if (pathname === undefined) {
        return pathname;
    }

    let seoPathname = pathname.replace(`/${i18n.locale}`, '');

    const hasTrailingSlash = seoPathname?.endsWith('/');

    // remove trailing slash
    if (hasTrailingSlash) {
        seoPathname = seoPathname.slice(0, -1);
    }

    const hasStartingSlash = seoPathname?.startsWith('/');

    // adding starting slash
    if (!hasStartingSlash) {
        seoPathname = `/${seoPathname}`;
    }

    if (withWildcard) {
        // replace everyhing after second / by :wildcard
        const indexOfSecondSlash = seoPathname.indexOf('/', 1);

        if (indexOfSecondSlash !== -1) {
            const removePastSecondSlash = seoPathname.slice(
                0,
                indexOfSecondSlash,
            );

            seoPathname = `${removePastSecondSlash}/:wildcard`;
        }
    }

    return seoPathname;
};

const useSeoMetaData = ({
    withWildcard,
}: {
    withWildcard?: boolean;
}): {
    loading: boolean;
    seoMetaData?: Partial<SeoType>;
} => {
    const location = useLocation();

    const { pathname } = location;

    const seoPathname = toSeoPathnameSyntax({
        pathname,
        withWildcard,
    })?.toLowerCase();

    const { loading, data } = useQuery<
        GetSeoByPathQuery,
        GetSeoByPathQueryVariables
    >(GetSeo, {
        skip: seoPathname === undefined,
        variables: { pathname: seoPathname },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    const seoMetaData = data?.seoByPath && {
        title: data?.seoByPath.title,
        description: data?.seoByPath.description,
        header: data?.seoByPath.header,
        byline: data?.seoByPath.byline,
    };

    return { loading, seoMetaData };
};

export default useSeoMetaData;
