import { PurchaseError } from '../Purchase.types';

export type HandlePurchaseError = (error?: PurchaseError) => void;

export const useHandlePurchaseError = ({
    showErrorNotification,
}: {
    showErrorNotification: () => void;
}) => {
    const handleError = () => {
        showErrorNotification();
    };

    return handleError;
};
