import {
    AcceptUserAgreementsInput,
    AccountBalanceSummary,
    Agreement,
    Agreements,
    AllSubscriptions,
    AllowedFeature,
    AlternateLanguage,
    Artist,
    Balance,
    Brandtype,
    Collection,
    ContentGroup,
    ContentList,
    CostPreviewResponse,
    CreatorSession,
    CreatorSessionInput,
    CreditBalancePayloadType,
    CurrentSubscription,
    Descriptor,
    Filter,
    FilterCategories,
    FilterFamily,
    FilterMeta,
    FilterWithLabel,
    Filters,
    Genre,
    GenreInput,
    HeroBanner,
    Instrument,
    InstrumentInput,
    Key,
    KeyInput,
    Label,
    LabelsChart,
    LabelsChartItem,
    MerchZone,
    MetaPage,
    Mutation,
    NextPage,
    OverduePayment,
    Pack,
    PackFavoritedInput,
    PackagesMeta,
    PacksChart,
    PacksChartItem,
    PaginatedArtists,
    PaginatedLabels,
    PaginatedPacks,
    PaginatedPacksOrRepacks,
    PaginatedRepacks,
    PaginatedSamples,
    PaginatedUserCollections,
    PlanFrequencyIdentifier,
    PluginPackages,
    PrismicBackgroundImage,
    PrismicBanner,
    PrismicDocument,
    PrismicImage,
    PrismicImageDimensions,
    PrismicSampleAuthBannerCtaLink,
    PrismicSampleAuthBannerCtaText,
    PrismicSampleAuthBannerData,
    PrismicSampleAuthBannerDescription,
    PrismicSampleAuthBannerTitle,
    PrismicSampleBannerData,
    PrismicSampleBannerGroupData,
    PrismicTextField,
    PromotionalCard,
    PurchasableCreatorSample,
    PurchasableCreatorSession,
    PurchaseResponse,
    Query,
    Repack,
    RepackFavoritedInput,
    RepacksChart,
    RepacksChartItem,
    Sample,
    SampleFavoritedInput,
    SampleKey,
    SampleKeyInput,
    SampleTypeFilter,
    Seo,
    SeoMetadata,
    SeoMetadataInput,
    Sfx,
    StreamUrl,
    SubItems,
    SubscriptionPlanProductDetails,
    SubscriptionsAndAllowedFeatures,
    Tag,
    Track,
    TrackInput,
    UnallowedReason,
    User,
    UserCollection,
    UserCollectionsFeed,
    UserCredits,
    UserRecentSearch,
    UserRecentSearches,
    Waveform,
    AccountStatusEnum,
    BpmMultiplier,
    ContentTarget,
    HeroBannerContentType,
    HomePageEnum,
    Interval,
    KeyQuality,
    KeyRootApi,
    KeySign,
    LegalAgreementKindEnum,
    Locale,
    MusicGenre,
    OccupationEnum,
    PackState,
    PackType,
    PremiumCodes,
    PromotionalCardType,
    ProviderType,
    RepackSubType,
    SampleState,
    SampleType,
    ServiceOfferKind,
    ServiceStatusEnum,
    SubscriptionProductStatus,
    SubscriptionStatusEnum,
    UserSearchFilter,
} from '../../types/generated/graphql';

export const anAcceptUserAgreementsInput = (
    overrides?: Partial<AcceptUserAgreementsInput>,
): AcceptUserAgreementsInput => {
    return {
        kinds:
            overrides && overrides.hasOwnProperty('kinds')
                ? overrides.kinds!
                : [LegalAgreementKindEnum.PrivacyPolicy],
    };
};

export const anAccountBalanceSummary = (
    overrides?: Partial<AccountBalanceSummary>,
): { __typename: 'AccountBalanceSummary' } & AccountBalanceSummary => {
    return {
        __typename: 'AccountBalanceSummary',
        accountingStatus:
            overrides && overrides.hasOwnProperty('accountingStatus')
                ? overrides.accountingStatus!
                : AccountStatusEnum.Collection,
        balance:
            overrides && overrides.hasOwnProperty('balance')
                ? overrides.balance!
                : 8.11,
        currencyCode:
            overrides && overrides.hasOwnProperty('currencyCode')
                ? overrides.currencyCode!
                : 'consequatur',
        currencySymbol:
            overrides && overrides.hasOwnProperty('currencySymbol')
                ? overrides.currencySymbol!
                : 'accusantium',
        overduePayments:
            overrides && overrides.hasOwnProperty('overduePayments')
                ? overrides.overduePayments!
                : [anOverduePayment()],
    };
};

export const anAgreement = (
    overrides?: Partial<Agreement>,
): { __typename: 'Agreement' } & Agreement => {
    return {
        __typename: 'Agreement',
        kind:
            overrides && overrides.hasOwnProperty('kind')
                ? overrides.kind!
                : LegalAgreementKindEnum.PrivacyPolicy,
        requireUpdate:
            overrides && overrides.hasOwnProperty('requireUpdate')
                ? overrides.requireUpdate!
                : true,
        version:
            overrides && overrides.hasOwnProperty('version')
                ? overrides.version!
                : 'voluptatem',
    };
};

export const anAgreements = (
    overrides?: Partial<Agreements>,
): { __typename: 'Agreements' } & Agreements => {
    return {
        __typename: 'Agreements',
        agreements:
            overrides && overrides.hasOwnProperty('agreements')
                ? overrides.agreements!
                : [anAgreement()],
    };
};

export const anAllSubscriptions = (
    overrides?: Partial<AllSubscriptions>,
): { __typename: 'AllSubscriptions' } & AllSubscriptions => {
    return {
        __typename: 'AllSubscriptions',
        subscriptions:
            overrides && overrides.hasOwnProperty('subscriptions')
                ? overrides.subscriptions!
                : [aCurrentSubscription()],
    };
};

export const anAllowedFeature = (
    overrides?: Partial<AllowedFeature>,
): { __typename: 'AllowedFeature' } & AllowedFeature => {
    return {
        __typename: 'AllowedFeature',
        isAllowed:
            overrides && overrides.hasOwnProperty('isAllowed')
                ? overrides.isAllowed!
                : true,
        unallowedReason:
            overrides && overrides.hasOwnProperty('unallowedReason')
                ? overrides.unallowedReason!
                : anUnallowedReason(),
    };
};

export const anAlternateLanguage = (
    overrides?: Partial<AlternateLanguage>,
): { __typename: 'AlternateLanguage' } & AlternateLanguage => {
    return {
        __typename: 'AlternateLanguage',
        id:
            overrides && overrides.hasOwnProperty('id')
                ? overrides.id!
                : 'da3000b3-c5f9-4e64-acb4-5843f4d3aec4',
        lang:
            overrides && overrides.hasOwnProperty('lang')
                ? overrides.lang!
                : 'veniam',
        type:
            overrides && overrides.hasOwnProperty('type')
                ? overrides.type!
                : 'et',
        uid:
            overrides && overrides.hasOwnProperty('uid')
                ? overrides.uid!
                : 'laudantium',
    };
};

export const anArtist = (
    overrides?: Partial<Artist>,
): { __typename: 'Artist' } & Artist => {
    return {
        __typename: 'Artist',
        artworkUrl:
            overrides && overrides.hasOwnProperty('artworkUrl')
                ? overrides.artworkUrl!
                : 'atque',
        coverUrl:
            overrides && overrides.hasOwnProperty('coverUrl')
                ? overrides.coverUrl!
                : 'quos',
        description:
            overrides && overrides.hasOwnProperty('description')
                ? overrides.description!
                : 'voluptatem',
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : '3134d417-6b24-4be0-be4a-879ee56ddad2',
        isExclusive:
            overrides && overrides.hasOwnProperty('isExclusive')
                ? overrides.isExclusive!
                : true,
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'hic',
        packCount:
            overrides && overrides.hasOwnProperty('packCount')
                ? overrides.packCount!
                : 9686,
        portraitUrl:
            overrides && overrides.hasOwnProperty('portraitUrl')
                ? overrides.portraitUrl!
                : 'laboriosam',
        sampleCount:
            overrides && overrides.hasOwnProperty('sampleCount')
                ? overrides.sampleCount!
                : 9704,
        seoMetadata:
            overrides && overrides.hasOwnProperty('seoMetadata')
                ? overrides.seoMetadata!
                : aSeoMetadata(),
        slug:
            overrides && overrides.hasOwnProperty('slug')
                ? overrides.slug!
                : 'rerum',
    };
};

export const aBalance = (
    overrides?: Partial<Balance>,
): { __typename: 'Balance' } & Balance => {
    return {
        __typename: 'Balance',
        balance:
            overrides && overrides.hasOwnProperty('balance')
                ? overrides.balance!
                : 8133,
    };
};

export const aBrandtype = (
    overrides?: Partial<Brandtype>,
): { __typename: 'Brandtype' } & Brandtype => {
    return {
        __typename: 'Brandtype',
        description:
            overrides && overrides.hasOwnProperty('description')
                ? overrides.description!
                : 'accusamus',
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : 'c44cd438-184b-46c6-b05f-c72d66d0c0a9',
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'tempora',
        packCount:
            overrides && overrides.hasOwnProperty('packCount')
                ? overrides.packCount!
                : 9746,
        sampleCount:
            overrides && overrides.hasOwnProperty('sampleCount')
                ? overrides.sampleCount!
                : 364,
        slug:
            overrides && overrides.hasOwnProperty('slug')
                ? overrides.slug!
                : 'voluptates',
    };
};

export const aCollection = (
    overrides?: Partial<Collection>,
): { __typename: 'Collection' } & Collection => {
    return {
        __typename: 'Collection',
        artworkUrl:
            overrides && overrides.hasOwnProperty('artworkUrl')
                ? overrides.artworkUrl!
                : 'accusamus',
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : '20c1b2d2-4935-452b-a79d-5cb183356766',
        isDownloaded:
            overrides && overrides.hasOwnProperty('isDownloaded')
                ? overrides.isDownloaded!
                : false,
        isExclusive:
            overrides && overrides.hasOwnProperty('isExclusive')
                ? overrides.isExclusive!
                : true,
        isFavorited:
            overrides && overrides.hasOwnProperty('isFavorited')
                ? overrides.isFavorited!
                : true,
        isFree:
            overrides && overrides.hasOwnProperty('isFree')
                ? overrides.isFree!
                : true,
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'illum',
        slug:
            overrides && overrides.hasOwnProperty('slug')
                ? overrides.slug!
                : 'sint',
        subType:
            overrides && overrides.hasOwnProperty('subType')
                ? overrides.subType!
                : RepackSubType.Chart,
        type:
            overrides && overrides.hasOwnProperty('type')
                ? overrides.type!
                : PackType.Pack,
    };
};

export const aContentGroup = (
    overrides?: Partial<ContentGroup>,
): { __typename: 'ContentGroup' } & ContentGroup => {
    return {
        __typename: 'ContentGroup',
        byline:
            overrides && overrides.hasOwnProperty('byline')
                ? overrides.byline!
                : 'vel',
        contentLists:
            overrides && overrides.hasOwnProperty('contentLists')
                ? overrides.contentLists!
                : [aContentList()],
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : 'ebde777d-dd0a-44da-9e84-dde510336aca',
        target:
            overrides && overrides.hasOwnProperty('target')
                ? overrides.target!
                : ContentTarget.AuthenticatedOnly,
        title:
            overrides && overrides.hasOwnProperty('title')
                ? overrides.title!
                : 'sint',
        to:
            overrides && overrides.hasOwnProperty('to')
                ? overrides.to!
                : 'quos',
    };
};

export const aContentList = (
    overrides?: Partial<ContentList>,
): { __typename: 'ContentList' } & ContentList => {
    return {
        __typename: 'ContentList',
        items:
            overrides && overrides.hasOwnProperty('items')
                ? overrides.items!
                : [aPack()],
        tabName:
            overrides && overrides.hasOwnProperty('tabName')
                ? overrides.tabName!
                : 'ut',
    };
};

export const aCostPreviewResponse = (
    overrides?: Partial<CostPreviewResponse>,
): { __typename: 'CostPreviewResponse' } & CostPreviewResponse => {
    return {
        __typename: 'CostPreviewResponse',
        credits:
            overrides && overrides.hasOwnProperty('credits')
                ? overrides.credits!
                : 8043,
        remainingCredits:
            overrides && overrides.hasOwnProperty('remainingCredits')
                ? overrides.remainingCredits!
                : 7842,
        unavailabilityReason:
            overrides && overrides.hasOwnProperty('unavailabilityReason')
                ? overrides.unavailabilityReason!
                : 'ut',
    };
};

export const aCreatorSession = (
    overrides?: Partial<CreatorSession>,
): { __typename: 'CreatorSession' } & CreatorSession => {
    return {
        __typename: 'CreatorSession',
        bpm:
            overrides && overrides.hasOwnProperty('bpm')
                ? overrides.bpm!
                : 4116,
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : '419748e2-6658-4b53-acdc-1abee98a5081',
        isPublic:
            overrides && overrides.hasOwnProperty('isPublic')
                ? overrides.isPublic!
                : true,
        key:
            overrides && overrides.hasOwnProperty('key')
                ? overrides.key!
                : aKey(),
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'laudantium',
        samples:
            overrides && overrides.hasOwnProperty('samples')
                ? overrides.samples!
                : [aTrack()],
        userId:
            overrides && overrides.hasOwnProperty('userId')
                ? overrides.userId!
                : '484903fb-8f67-412a-96e1-faab3e5c67f2',
        version:
            overrides && overrides.hasOwnProperty('version')
                ? overrides.version!
                : 5811,
    };
};

export const aCreatorSessionInput = (
    overrides?: Partial<CreatorSessionInput>,
): CreatorSessionInput => {
    return {
        bpm:
            overrides && overrides.hasOwnProperty('bpm')
                ? overrides.bpm!
                : 3691,
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : '9d31911d-5023-4178-8293-dad89254d57c',
        isPublic:
            overrides && overrides.hasOwnProperty('isPublic')
                ? overrides.isPublic!
                : false,
        key:
            overrides && overrides.hasOwnProperty('key')
                ? overrides.key!
                : aKeyInput(),
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'voluptatibus',
        samples:
            overrides && overrides.hasOwnProperty('samples')
                ? overrides.samples!
                : [aTrackInput()],
        version:
            overrides && overrides.hasOwnProperty('version')
                ? overrides.version!
                : 2923,
    };
};

export const aCreditBalancePayloadType = (
    overrides?: Partial<CreditBalancePayloadType>,
): { __typename: 'CreditBalancePayloadType' } & CreditBalancePayloadType => {
    return {
        __typename: 'CreditBalancePayloadType',
        hdwav:
            overrides && overrides.hasOwnProperty('hdwav')
                ? overrides.hdwav!
                : aBalance(),
        mp3:
            overrides && overrides.hasOwnProperty('mp3')
                ? overrides.mp3!
                : aBalance(),
        sample:
            overrides && overrides.hasOwnProperty('sample')
                ? overrides.sample!
                : aBalance(),
        wav:
            overrides && overrides.hasOwnProperty('wav')
                ? overrides.wav!
                : aBalance(),
    };
};

export const aCurrentSubscription = (
    overrides?: Partial<CurrentSubscription>,
): { __typename: 'CurrentSubscription' } & CurrentSubscription => {
    return {
        __typename: 'CurrentSubscription',
        activationDate:
            overrides && overrides.hasOwnProperty('activationDate')
                ? overrides.activationDate!
                : 'qui',
        creationDate:
            overrides && overrides.hasOwnProperty('creationDate')
                ? overrides.creationDate!
                : 'tempore',
        endDate:
            overrides && overrides.hasOwnProperty('endDate')
                ? overrides.endDate!
                : 'officia',
        id:
            overrides && overrides.hasOwnProperty('id')
                ? overrides.id!
                : '91e12f1e-acc5-4f21-882c-d6969f3dfcb9',
        isTrial:
            overrides && overrides.hasOwnProperty('isTrial')
                ? overrides.isTrial!
                : true,
        nextPeriodStartDate:
            overrides && overrides.hasOwnProperty('nextPeriodStartDate')
                ? overrides.nextPeriodStartDate!
                : 'nihil',
        plan:
            overrides && overrides.hasOwnProperty('plan')
                ? overrides.plan!
                : aPlanFrequencyIdentifier(),
        segment:
            overrides && overrides.hasOwnProperty('segment')
                ? overrides.segment!
                : 'minima',
        serviceOffer:
            overrides && overrides.hasOwnProperty('serviceOffer')
                ? overrides.serviceOffer!
                : ServiceOfferKind.Bundles,
        status:
            overrides && overrides.hasOwnProperty('status')
                ? overrides.status!
                : SubscriptionStatusEnum.Active,
    };
};

export const aDescriptor = (
    overrides?: Partial<Descriptor>,
): { __typename: 'Descriptor' } & Descriptor => {
    return {
        __typename: 'Descriptor',
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'officia',
    };
};

export const aFilter = (
    overrides?: Partial<Filter>,
): { __typename: 'Filter' } & Filter => {
    return {
        __typename: 'Filter',
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'aspernatur',
    };
};

export const aFilterCategories = (
    overrides?: Partial<FilterCategories>,
): { __typename: 'FilterCategories' } & FilterCategories => {
    return {
        __typename: 'FilterCategories',
        genres:
            overrides && overrides.hasOwnProperty('genres')
                ? overrides.genres!
                : [aFilterFamily()],
        instruments:
            overrides && overrides.hasOwnProperty('instruments')
                ? overrides.instruments!
                : [aFilterFamily()],
        sampleTypes:
            overrides && overrides.hasOwnProperty('sampleTypes')
                ? overrides.sampleTypes!
                : [aFilter()],
        sfxs:
            overrides && overrides.hasOwnProperty('sfxs')
                ? overrides.sfxs!
                : [aFilterFamily()],
        tags:
            overrides && overrides.hasOwnProperty('tags')
                ? overrides.tags!
                : [aFilterWithLabel()],
    };
};

export const aFilterFamily = (
    overrides?: Partial<FilterFamily>,
): { __typename: 'FilterFamily' } & FilterFamily => {
    return {
        __typename: 'FilterFamily',
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'dolorem',
        subFilters:
            overrides && overrides.hasOwnProperty('subFilters')
                ? overrides.subFilters!
                : [aFilter()],
    };
};

export const aFilterMeta = (
    overrides?: Partial<FilterMeta>,
): { __typename: 'FilterMeta' } & FilterMeta => {
    return {
        __typename: 'FilterMeta',
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'ut',
        value:
            overrides && overrides.hasOwnProperty('value')
                ? overrides.value!
                : 735,
    };
};

export const aFilterWithLabel = (
    overrides?: Partial<FilterWithLabel>,
): { __typename: 'FilterWithLabel' } & FilterWithLabel => {
    return {
        __typename: 'FilterWithLabel',
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'dolores',
        label:
            overrides && overrides.hasOwnProperty('label')
                ? overrides.label!
                : 'corrupti',
    };
};

export const aFilters = (
    overrides?: Partial<Filters>,
): { __typename: 'Filters' } & Filters => {
    return {
        __typename: 'Filters',
        descriptorFilters:
            overrides && overrides.hasOwnProperty('descriptorFilters')
                ? overrides.descriptorFilters!
                : [aFilterMeta()],
        genreFilters:
            overrides && overrides.hasOwnProperty('genreFilters')
                ? overrides.genreFilters!
                : [aFilterMeta()],
        instrumentFilters:
            overrides && overrides.hasOwnProperty('instrumentFilters')
                ? overrides.instrumentFilters!
                : [aFilterMeta()],
        sampleTypeFilters:
            overrides && overrides.hasOwnProperty('sampleTypeFilters')
                ? overrides.sampleTypeFilters!
                : [aFilterMeta()],
        sfxFilters:
            overrides && overrides.hasOwnProperty('sfxFilters')
                ? overrides.sfxFilters!
                : [aFilterMeta()],
        tagFilters:
            overrides && overrides.hasOwnProperty('tagFilters')
                ? overrides.tagFilters!
                : [aFilterMeta()],
    };
};

export const aGenre = (
    overrides?: Partial<Genre>,
): { __typename: 'Genre' } & Genre => {
    return {
        __typename: 'Genre',
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'maiores',
        subGenres:
            overrides && overrides.hasOwnProperty('subGenres')
                ? overrides.subGenres!
                : [aGenre()],
    };
};

export const aGenreInput = (overrides?: Partial<GenreInput>): GenreInput => {
    return {
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'sed',
        subGenres:
            overrides && overrides.hasOwnProperty('subGenres')
                ? overrides.subGenres!
                : [aGenreInput()],
    };
};

export const aHeroBanner = (
    overrides?: Partial<HeroBanner>,
): { __typename: 'HeroBanner' } & HeroBanner => {
    return {
        __typename: 'HeroBanner',
        byline:
            overrides && overrides.hasOwnProperty('byline')
                ? overrides.byline!
                : 'velit',
        contentType:
            overrides && overrides.hasOwnProperty('contentType')
                ? overrides.contentType!
                : HeroBannerContentType.Collection,
        customArtworkUrl:
            overrides && overrides.hasOwnProperty('customArtworkUrl')
                ? overrides.customArtworkUrl!
                : 'aliquam',
        customDemoUrl:
            overrides && overrides.hasOwnProperty('customDemoUrl')
                ? overrides.customDemoUrl!
                : 'totam',
        customTags:
            overrides && overrides.hasOwnProperty('customTags')
                ? overrides.customTags!
                : ['occaecati'],
        description:
            overrides && overrides.hasOwnProperty('description')
                ? overrides.description!
                : 'eaque',
        entity:
            overrides && overrides.hasOwnProperty('entity')
                ? overrides.entity!
                : aLabel(),
        genres:
            overrides && overrides.hasOwnProperty('genres')
                ? overrides.genres!
                : [aGenre()],
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : 'voluptas',
        instruments:
            overrides && overrides.hasOwnProperty('instruments')
                ? overrides.instruments!
                : [anInstrument()],
        labelPacks:
            overrides && overrides.hasOwnProperty('labelPacks')
                ? overrides.labelPacks!
                : [aPack()],
        title:
            overrides && overrides.hasOwnProperty('title')
                ? overrides.title!
                : 'consequuntur',
    };
};

export const anInstrument = (
    overrides?: Partial<Instrument>,
): { __typename: 'Instrument' } & Instrument => {
    return {
        __typename: 'Instrument',
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'omnis',
        isFamily:
            overrides && overrides.hasOwnProperty('isFamily')
                ? overrides.isFamily!
                : true,
        subInstruments:
            overrides && overrides.hasOwnProperty('subInstruments')
                ? overrides.subInstruments!
                : [anInstrument()],
    };
};

export const anInstrumentInput = (
    overrides?: Partial<InstrumentInput>,
): InstrumentInput => {
    return {
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'autem',
        isFamily:
            overrides && overrides.hasOwnProperty('isFamily')
                ? overrides.isFamily!
                : true,
        subInstruments:
            overrides && overrides.hasOwnProperty('subInstruments')
                ? overrides.subInstruments!
                : [anInstrumentInput()],
    };
};

export const aKey = (overrides?: Partial<Key>): { __typename: 'Key' } & Key => {
    return {
        __typename: 'Key',
        quality:
            overrides && overrides.hasOwnProperty('quality')
                ? overrides.quality!
                : KeyQuality.Major,
        root:
            overrides && overrides.hasOwnProperty('root')
                ? overrides.root!
                : KeyRootApi.A,
        sign:
            overrides && overrides.hasOwnProperty('sign')
                ? overrides.sign!
                : KeySign.Flat,
    };
};

export const aKeyInput = (overrides?: Partial<KeyInput>): KeyInput => {
    return {
        quality:
            overrides && overrides.hasOwnProperty('quality')
                ? overrides.quality!
                : KeyQuality.Major,
        root:
            overrides && overrides.hasOwnProperty('root')
                ? overrides.root!
                : KeyRootApi.A,
        sign:
            overrides && overrides.hasOwnProperty('sign')
                ? overrides.sign!
                : KeySign.Flat,
    };
};

export const aLabel = (
    overrides?: Partial<Label>,
): { __typename: 'Label' } & Label => {
    return {
        __typename: 'Label',
        artworkUrl:
            overrides && overrides.hasOwnProperty('artworkUrl')
                ? overrides.artworkUrl!
                : 'deleniti',
        coverUrl:
            overrides && overrides.hasOwnProperty('coverUrl')
                ? overrides.coverUrl!
                : 'provident',
        description:
            overrides && overrides.hasOwnProperty('description')
                ? overrides.description!
                : 'dolorem',
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : 'c5ae1a36-2497-4aba-baa7-7eb907453d79',
        isExclusive:
            overrides && overrides.hasOwnProperty('isExclusive')
                ? overrides.isExclusive!
                : true,
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'commodi',
        packCount:
            overrides && overrides.hasOwnProperty('packCount')
                ? overrides.packCount!
                : 9768,
        sampleCount:
            overrides && overrides.hasOwnProperty('sampleCount')
                ? overrides.sampleCount!
                : 7364,
        seoMetadata:
            overrides && overrides.hasOwnProperty('seoMetadata')
                ? overrides.seoMetadata!
                : aSeoMetadata(),
        slug:
            overrides && overrides.hasOwnProperty('slug')
                ? overrides.slug!
                : 'vel',
    };
};

export const aLabelsChart = (
    overrides?: Partial<LabelsChart>,
): { __typename: 'LabelsChart' } & LabelsChart => {
    return {
        __typename: 'LabelsChart',
        items:
            overrides && overrides.hasOwnProperty('items')
                ? overrides.items!
                : [aLabelsChartItem()],
    };
};

export const aLabelsChartItem = (
    overrides?: Partial<LabelsChartItem>,
): { __typename: 'LabelsChartItem' } & LabelsChartItem => {
    return {
        __typename: 'LabelsChartItem',
        artistName:
            overrides && overrides.hasOwnProperty('artistName')
                ? overrides.artistName!
                : 'distinctio',
        artworkUrl:
            overrides && overrides.hasOwnProperty('artworkUrl')
                ? overrides.artworkUrl!
                : 'enim',
        currentRank:
            overrides && overrides.hasOwnProperty('currentRank')
                ? overrides.currentRank!
                : 8049,
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : '7105df85-d1ab-41bc-9e1a-2898ac2c67c3',
        isNew:
            overrides && overrides.hasOwnProperty('isNew')
                ? overrides.isNew!
                : false,
        lastRank:
            overrides && overrides.hasOwnProperty('lastRank')
                ? overrides.lastRank!
                : 720,
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'et',
        slug:
            overrides && overrides.hasOwnProperty('slug')
                ? overrides.slug!
                : 'libero',
        subItemsCount:
            overrides && overrides.hasOwnProperty('subItemsCount')
                ? overrides.subItemsCount!
                : 5143,
    };
};

export const aMerchZone = (
    overrides?: Partial<MerchZone>,
): { __typename: 'MerchZone' } & MerchZone => {
    return {
        __typename: 'MerchZone',
        contentGroups:
            overrides && overrides.hasOwnProperty('contentGroups')
                ? overrides.contentGroups!
                : [aContentGroup()],
    };
};

export const aMetaPage = (
    overrides?: Partial<MetaPage>,
): { __typename: 'MetaPage' } & MetaPage => {
    return {
        __typename: 'MetaPage',
        filters:
            overrides && overrides.hasOwnProperty('filters')
                ? overrides.filters!
                : aFilters(),
        totalPackRecords:
            overrides && overrides.hasOwnProperty('totalPackRecords')
                ? overrides.totalPackRecords!
                : 8957,
        totalRecords:
            overrides && overrides.hasOwnProperty('totalRecords')
                ? overrides.totalRecords!
                : 7219,
        totalRepackRecords:
            overrides && overrides.hasOwnProperty('totalRepackRecords')
                ? overrides.totalRepackRecords!
                : 3703,
        totalSampleRecords:
            overrides && overrides.hasOwnProperty('totalSampleRecords')
                ? overrides.totalSampleRecords!
                : 2624,
    };
};

export const aMutation = (
    overrides?: Partial<Mutation>,
): { __typename: 'Mutation' } & Mutation => {
    return {
        __typename: 'Mutation',
        acceptUserAgreements:
            overrides && overrides.hasOwnProperty('acceptUserAgreements')
                ? overrides.acceptUserAgreements!
                : false,
        activateTrialSubscription:
            overrides && overrides.hasOwnProperty('activateTrialSubscription')
                ? overrides.activateTrialSubscription!
                : aSubscriptionsAndAllowedFeatures(),
        addSamplesToUserCollection:
            overrides && overrides.hasOwnProperty('addSamplesToUserCollection')
                ? overrides.addSamplesToUserCollection!
                : aUserCollection(),
        createUserCollection:
            overrides && overrides.hasOwnProperty('createUserCollection')
                ? overrides.createUserCollection!
                : aUserCollection(),
        createUserRecentSearch:
            overrides && overrides.hasOwnProperty('createUserRecentSearch')
                ? overrides.createUserRecentSearch!
                : 'atque',
        deleteUserCollection:
            overrides && overrides.hasOwnProperty('deleteUserCollection')
                ? overrides.deleteUserCollection!
                : 'praesentium',
        deleteUserRecentSearch:
            overrides && overrides.hasOwnProperty('deleteUserRecentSearch')
                ? overrides.deleteUserRecentSearch!
                : 'rem',
        editUserCollection:
            overrides && overrides.hasOwnProperty('editUserCollection')
                ? overrides.editUserCollection!
                : 'et',
        putMilestones:
            overrides && overrides.hasOwnProperty('putMilestones')
                ? overrides.putMilestones!
                : ['aliquam'],
        removeSamplesFromUserCollection:
            overrides &&
            overrides.hasOwnProperty('removeSamplesFromUserCollection')
                ? overrides.removeSamplesFromUserCollection!
                : aUserCollection(),
        storeCreatorSession:
            overrides && overrides.hasOwnProperty('storeCreatorSession')
                ? overrides.storeCreatorSession!
                : aCreatorSession(),
        updatePackFavoritedState:
            overrides && overrides.hasOwnProperty('updatePackFavoritedState')
                ? overrides.updatePackFavoritedState!
                : aPack(),
        updateRepackFavoritedState:
            overrides && overrides.hasOwnProperty('updateRepackFavoritedState')
                ? overrides.updateRepackFavoritedState!
                : aRepack(),
        updateSampleFavoritedState:
            overrides && overrides.hasOwnProperty('updateSampleFavoritedState')
                ? overrides.updateSampleFavoritedState!
                : aSample(),
    };
};

export const aNextPage = (
    overrides?: Partial<NextPage>,
): { __typename: 'NextPage' } & NextPage => {
    return {
        __typename: 'NextPage',
        skip:
            overrides && overrides.hasOwnProperty('skip')
                ? overrides.skip!
                : 5911,
    };
};

export const anOverduePayment = (
    overrides?: Partial<OverduePayment>,
): { __typename: 'OverduePayment' } & OverduePayment => {
    return {
        __typename: 'OverduePayment',
        serviceOffer:
            overrides && overrides.hasOwnProperty('serviceOffer')
                ? overrides.serviceOffer!
                : ServiceOfferKind.Bundles,
    };
};

export const aPack = (
    overrides?: Partial<Pack>,
): { __typename: 'Pack' } & Pack => {
    return {
        __typename: 'Pack',
        artist:
            overrides && overrides.hasOwnProperty('artist')
                ? overrides.artist!
                : anArtist(),
        artworkUrl:
            overrides && overrides.hasOwnProperty('artworkUrl')
                ? overrides.artworkUrl!
                : 'omnis',
        backgroundColor:
            overrides && overrides.hasOwnProperty('backgroundColor')
                ? overrides.backgroundColor!
                : 'autem',
        creatorSession:
            overrides && overrides.hasOwnProperty('creatorSession')
                ? overrides.creatorSession!
                : '494aa2b9-7266-42b5-be32-db2fc67d36b1',
        description:
            overrides && overrides.hasOwnProperty('description')
                ? overrides.description!
                : 'sint',
        foregroundColor:
            overrides && overrides.hasOwnProperty('foregroundColor')
                ? overrides.foregroundColor!
                : 'laudantium',
        genres:
            overrides && overrides.hasOwnProperty('genres')
                ? overrides.genres!
                : [aGenre()],
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : 'a5a43f37-9da7-43c4-8e7e-6fbba52d490f',
        id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 1202,
        isDownloaded:
            overrides && overrides.hasOwnProperty('isDownloaded')
                ? overrides.isDownloaded!
                : false,
        isExclusive:
            overrides && overrides.hasOwnProperty('isExclusive')
                ? overrides.isExclusive!
                : true,
        isFavorited:
            overrides && overrides.hasOwnProperty('isFavorited')
                ? overrides.isFavorited!
                : true,
        isFree:
            overrides && overrides.hasOwnProperty('isFree')
                ? overrides.isFree!
                : true,
        label:
            overrides && overrides.hasOwnProperty('label')
                ? overrides.label!
                : aLabel(),
        mainGenre:
            overrides && overrides.hasOwnProperty('mainGenre')
                ? overrides.mainGenre!
                : aGenre(),
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'nostrum',
        packState:
            overrides && overrides.hasOwnProperty('packState')
                ? overrides.packState!
                : PackState.Draft,
        premiumCodes:
            overrides && overrides.hasOwnProperty('premiumCodes')
                ? overrides.premiumCodes!
                : [PremiumCodes.LandrStudio],
        sampleCount:
            overrides && overrides.hasOwnProperty('sampleCount')
                ? overrides.sampleCount!
                : 1635,
        seoMetadata:
            overrides && overrides.hasOwnProperty('seoMetadata')
                ? overrides.seoMetadata!
                : aSeoMetadata(),
        sfxs:
            overrides && overrides.hasOwnProperty('sfxs')
                ? overrides.sfxs!
                : [aSfx()],
        slug:
            overrides && overrides.hasOwnProperty('slug')
                ? overrides.slug!
                : 'enim',
        type:
            overrides && overrides.hasOwnProperty('type')
                ? overrides.type!
                : PackType.Pack,
    };
};

export const aPackFavoritedInput = (
    overrides?: Partial<PackFavoritedInput>,
): PackFavoritedInput => {
    return {
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : '0e280df6-b296-4a58-9106-1f63e2afb15c',
        isFavorited:
            overrides && overrides.hasOwnProperty('isFavorited')
                ? overrides.isFavorited!
                : false,
    };
};

export const aPackagesMeta = (
    overrides?: Partial<PackagesMeta>,
): { __typename: 'PackagesMeta' } & PackagesMeta => {
    return {
        __typename: 'PackagesMeta',
        url:
            overrides && overrides.hasOwnProperty('url')
                ? overrides.url!
                : 'quaerat',
        version:
            overrides && overrides.hasOwnProperty('version')
                ? overrides.version!
                : 'qui',
    };
};

export const aPacksChart = (
    overrides?: Partial<PacksChart>,
): { __typename: 'PacksChart' } & PacksChart => {
    return {
        __typename: 'PacksChart',
        items:
            overrides && overrides.hasOwnProperty('items')
                ? overrides.items!
                : [aPacksChartItem()],
    };
};

export const aPacksChartItem = (
    overrides?: Partial<PacksChartItem>,
): { __typename: 'PacksChartItem' } & PacksChartItem => {
    return {
        __typename: 'PacksChartItem',
        artistName:
            overrides && overrides.hasOwnProperty('artistName')
                ? overrides.artistName!
                : 'facilis',
        artworkUrl:
            overrides && overrides.hasOwnProperty('artworkUrl')
                ? overrides.artworkUrl!
                : 'aut',
        currentRank:
            overrides && overrides.hasOwnProperty('currentRank')
                ? overrides.currentRank!
                : 7457,
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : '8f8d4401-cb2f-45bb-8505-1a8ada34bc0a',
        isNew:
            overrides && overrides.hasOwnProperty('isNew')
                ? overrides.isNew!
                : false,
        lastRank:
            overrides && overrides.hasOwnProperty('lastRank')
                ? overrides.lastRank!
                : 3877,
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'ipsam',
        slug:
            overrides && overrides.hasOwnProperty('slug')
                ? overrides.slug!
                : 'et',
        subItemsCount:
            overrides && overrides.hasOwnProperty('subItemsCount')
                ? overrides.subItemsCount!
                : 6076,
    };
};

export const aPaginatedArtists = (
    overrides?: Partial<PaginatedArtists>,
): { __typename: 'PaginatedArtists' } & PaginatedArtists => {
    return {
        __typename: 'PaginatedArtists',
        data:
            overrides && overrides.hasOwnProperty('data')
                ? overrides.data!
                : [anArtist()],
        meta:
            overrides && overrides.hasOwnProperty('meta')
                ? overrides.meta!
                : aMetaPage(),
        next:
            overrides && overrides.hasOwnProperty('next')
                ? overrides.next!
                : aNextPage(),
    };
};

export const aPaginatedLabels = (
    overrides?: Partial<PaginatedLabels>,
): { __typename: 'PaginatedLabels' } & PaginatedLabels => {
    return {
        __typename: 'PaginatedLabels',
        data:
            overrides && overrides.hasOwnProperty('data')
                ? overrides.data!
                : [aLabel()],
        meta:
            overrides && overrides.hasOwnProperty('meta')
                ? overrides.meta!
                : aMetaPage(),
        next:
            overrides && overrides.hasOwnProperty('next')
                ? overrides.next!
                : aNextPage(),
    };
};

export const aPaginatedPacks = (
    overrides?: Partial<PaginatedPacks>,
): { __typename: 'PaginatedPacks' } & PaginatedPacks => {
    return {
        __typename: 'PaginatedPacks',
        data:
            overrides && overrides.hasOwnProperty('data')
                ? overrides.data!
                : [aPack()],
        meta:
            overrides && overrides.hasOwnProperty('meta')
                ? overrides.meta!
                : aMetaPage(),
        next:
            overrides && overrides.hasOwnProperty('next')
                ? overrides.next!
                : aNextPage(),
    };
};

export const aPaginatedPacksOrRepacks = (
    overrides?: Partial<PaginatedPacksOrRepacks>,
): { __typename: 'PaginatedPacksOrRepacks' } & PaginatedPacksOrRepacks => {
    return {
        __typename: 'PaginatedPacksOrRepacks',
        data:
            overrides && overrides.hasOwnProperty('data')
                ? overrides.data!
                : [aPack()],
        meta:
            overrides && overrides.hasOwnProperty('meta')
                ? overrides.meta!
                : aMetaPage(),
        next:
            overrides && overrides.hasOwnProperty('next')
                ? overrides.next!
                : aNextPage(),
    };
};

export const aPaginatedRepacks = (
    overrides?: Partial<PaginatedRepacks>,
): { __typename: 'PaginatedRepacks' } & PaginatedRepacks => {
    return {
        __typename: 'PaginatedRepacks',
        data:
            overrides && overrides.hasOwnProperty('data')
                ? overrides.data!
                : [aRepack()],
        meta:
            overrides && overrides.hasOwnProperty('meta')
                ? overrides.meta!
                : aMetaPage(),
        next:
            overrides && overrides.hasOwnProperty('next')
                ? overrides.next!
                : aNextPage(),
    };
};

export const aPaginatedSamples = (
    overrides?: Partial<PaginatedSamples>,
): { __typename: 'PaginatedSamples' } & PaginatedSamples => {
    return {
        __typename: 'PaginatedSamples',
        data:
            overrides && overrides.hasOwnProperty('data')
                ? overrides.data!
                : [aSample()],
        meta:
            overrides && overrides.hasOwnProperty('meta')
                ? overrides.meta!
                : aMetaPage(),
        next:
            overrides && overrides.hasOwnProperty('next')
                ? overrides.next!
                : aNextPage(),
    };
};

export const aPaginatedUserCollections = (
    overrides?: Partial<PaginatedUserCollections>,
): { __typename: 'PaginatedUserCollections' } & PaginatedUserCollections => {
    return {
        __typename: 'PaginatedUserCollections',
        data:
            overrides && overrides.hasOwnProperty('data')
                ? overrides.data!
                : [aUserCollection()],
        meta:
            overrides && overrides.hasOwnProperty('meta')
                ? overrides.meta!
                : aMetaPage(),
        next:
            overrides && overrides.hasOwnProperty('next')
                ? overrides.next!
                : aNextPage(),
    };
};

export const aPlanFrequencyIdentifier = (
    overrides?: Partial<PlanFrequencyIdentifier>,
): { __typename: 'PlanFrequencyIdentifier' } & PlanFrequencyIdentifier => {
    return {
        __typename: 'PlanFrequencyIdentifier',
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'ut',
        frequency:
            overrides && overrides.hasOwnProperty('frequency')
                ? overrides.frequency!
                : Interval.Monthly,
    };
};

export const aPluginPackages = (
    overrides?: Partial<PluginPackages>,
): { __typename: 'PluginPackages' } & PluginPackages => {
    return {
        __typename: 'PluginPackages',
        macOS:
            overrides && overrides.hasOwnProperty('macOS')
                ? overrides.macOS!
                : aPackagesMeta(),
        windows:
            overrides && overrides.hasOwnProperty('windows')
                ? overrides.windows!
                : aPackagesMeta(),
    };
};

export const aPrismicBackgroundImage = (
    overrides?: Partial<PrismicBackgroundImage>,
): { __typename: 'PrismicBackgroundImage' } & PrismicBackgroundImage => {
    return {
        __typename: 'PrismicBackgroundImage',
        alt:
            overrides && overrides.hasOwnProperty('alt')
                ? overrides.alt!
                : 'molestiae',
        copyright:
            overrides && overrides.hasOwnProperty('copyright')
                ? overrides.copyright!
                : 'est',
        dimensions:
            overrides && overrides.hasOwnProperty('dimensions')
                ? overrides.dimensions!
                : aPrismicImageDimensions(),
        regular:
            overrides && overrides.hasOwnProperty('regular')
                ? overrides.regular!
                : aPrismicImage(),
        url:
            overrides && overrides.hasOwnProperty('url')
                ? overrides.url!
                : 'deleniti',
    };
};

export const aPrismicBanner = (
    overrides?: Partial<PrismicBanner>,
): { __typename: 'PrismicBanner' } & PrismicBanner => {
    return {
        __typename: 'PrismicBanner',
        first_publication_date:
            overrides && overrides.hasOwnProperty('first_publication_date')
                ? overrides.first_publication_date!
                : '1970-01-10T07:19:31.032Z',
        id:
            overrides && overrides.hasOwnProperty('id')
                ? overrides.id!
                : '0dde0f59-b779-427c-b0f5-06d608f0ea68',
        isBroken:
            overrides && overrides.hasOwnProperty('isBroken')
                ? overrides.isBroken!
                : false,
        lang:
            overrides && overrides.hasOwnProperty('lang')
                ? overrides.lang!
                : 'nobis',
        last_publication_date:
            overrides && overrides.hasOwnProperty('last_publication_date')
                ? overrides.last_publication_date!
                : '1970-01-10T05:02:01.038Z',
        link_type:
            overrides && overrides.hasOwnProperty('link_type')
                ? overrides.link_type!
                : 'vero',
        slug:
            overrides && overrides.hasOwnProperty('slug')
                ? overrides.slug!
                : 'corrupti',
        tags:
            overrides && overrides.hasOwnProperty('tags')
                ? overrides.tags!
                : ['velit'],
        type:
            overrides && overrides.hasOwnProperty('type')
                ? overrides.type!
                : 'aperiam',
    };
};

export const aPrismicDocument = (
    overrides?: Partial<PrismicDocument>,
): { __typename: 'PrismicDocument' } & PrismicDocument => {
    return {
        __typename: 'PrismicDocument',
        alternate_languages:
            overrides && overrides.hasOwnProperty('alternate_languages')
                ? overrides.alternate_languages!
                : [anAlternateLanguage()],
        data:
            overrides && overrides.hasOwnProperty('data')
                ? overrides.data!
                : aPrismicSampleAuthBannerData(),
        first_publication_date:
            overrides && overrides.hasOwnProperty('first_publication_date')
                ? overrides.first_publication_date!
                : 'perferendis',
        href:
            overrides && overrides.hasOwnProperty('href')
                ? overrides.href!
                : 'labore',
        id:
            overrides && overrides.hasOwnProperty('id')
                ? overrides.id!
                : 'ceb64cec-b45d-4ba9-befc-7e7192d42d68',
        lang:
            overrides && overrides.hasOwnProperty('lang')
                ? overrides.lang!
                : 'occaecati',
        last_publication_date:
            overrides && overrides.hasOwnProperty('last_publication_date')
                ? overrides.last_publication_date!
                : 'sapiente',
        slugs:
            overrides && overrides.hasOwnProperty('slugs')
                ? overrides.slugs!
                : ['quisquam'],
        tags:
            overrides && overrides.hasOwnProperty('tags')
                ? overrides.tags!
                : ['enim'],
        type:
            overrides && overrides.hasOwnProperty('type')
                ? overrides.type!
                : 'rem',
        uid:
            overrides && overrides.hasOwnProperty('uid')
                ? overrides.uid!
                : 'aut',
    };
};

export const aPrismicImage = (
    overrides?: Partial<PrismicImage>,
): { __typename: 'PrismicImage' } & PrismicImage => {
    return {
        __typename: 'PrismicImage',
        alt:
            overrides && overrides.hasOwnProperty('alt')
                ? overrides.alt!
                : 'hic',
        copyright:
            overrides && overrides.hasOwnProperty('copyright')
                ? overrides.copyright!
                : 'deserunt',
        dimensions:
            overrides && overrides.hasOwnProperty('dimensions')
                ? overrides.dimensions!
                : aPrismicImageDimensions(),
        url:
            overrides && overrides.hasOwnProperty('url')
                ? overrides.url!
                : 'ut',
    };
};

export const aPrismicImageDimensions = (
    overrides?: Partial<PrismicImageDimensions>,
): { __typename: 'PrismicImageDimensions' } & PrismicImageDimensions => {
    return {
        __typename: 'PrismicImageDimensions',
        height:
            overrides && overrides.hasOwnProperty('height')
                ? overrides.height!
                : 2323,
        width:
            overrides && overrides.hasOwnProperty('width')
                ? overrides.width!
                : 9060,
    };
};

export const aPrismicSampleAuthBannerCtaLink = (
    overrides?: Partial<PrismicSampleAuthBannerCtaLink>,
): {
    __typename: 'PrismicSampleAuthBannerCtaLink';
} & PrismicSampleAuthBannerCtaLink => {
    return {
        __typename: 'PrismicSampleAuthBannerCtaLink',
        authenticated_cta_link:
            overrides && overrides.hasOwnProperty('authenticated_cta_link')
                ? overrides.authenticated_cta_link!
                : [aPrismicTextField()],
        guest_cta_link:
            overrides && overrides.hasOwnProperty('guest_cta_link')
                ? overrides.guest_cta_link!
                : [aPrismicTextField()],
    };
};

export const aPrismicSampleAuthBannerCtaText = (
    overrides?: Partial<PrismicSampleAuthBannerCtaText>,
): {
    __typename: 'PrismicSampleAuthBannerCtaText';
} & PrismicSampleAuthBannerCtaText => {
    return {
        __typename: 'PrismicSampleAuthBannerCtaText',
        authenticated_cta_text:
            overrides && overrides.hasOwnProperty('authenticated_cta_text')
                ? overrides.authenticated_cta_text!
                : [aPrismicTextField()],
        guest_cta_text:
            overrides && overrides.hasOwnProperty('guest_cta_text')
                ? overrides.guest_cta_text!
                : [aPrismicTextField()],
    };
};

export const aPrismicSampleAuthBannerData = (
    overrides?: Partial<PrismicSampleAuthBannerData>,
): {
    __typename: 'PrismicSampleAuthBannerData';
} & PrismicSampleAuthBannerData => {
    return {
        __typename: 'PrismicSampleAuthBannerData',
        background_image:
            overrides && overrides.hasOwnProperty('background_image')
                ? overrides.background_image!
                : aPrismicBackgroundImage(),
        banner_title:
            overrides && overrides.hasOwnProperty('banner_title')
                ? overrides.banner_title!
                : [aPrismicTextField()],
        cta_link:
            overrides && overrides.hasOwnProperty('cta_link')
                ? overrides.cta_link!
                : [aPrismicSampleAuthBannerCtaLink()],
        cta_text:
            overrides && overrides.hasOwnProperty('cta_text')
                ? overrides.cta_text!
                : [aPrismicSampleAuthBannerCtaText()],
        description:
            overrides && overrides.hasOwnProperty('description')
                ? overrides.description!
                : [aPrismicSampleAuthBannerDescription()],
        title:
            overrides && overrides.hasOwnProperty('title')
                ? overrides.title!
                : [aPrismicSampleAuthBannerTitle()],
    };
};

export const aPrismicSampleAuthBannerDescription = (
    overrides?: Partial<PrismicSampleAuthBannerDescription>,
): {
    __typename: 'PrismicSampleAuthBannerDescription';
} & PrismicSampleAuthBannerDescription => {
    return {
        __typename: 'PrismicSampleAuthBannerDescription',
        authenticated_description:
            overrides && overrides.hasOwnProperty('authenticated_description')
                ? overrides.authenticated_description!
                : [aPrismicTextField()],
        guest_description:
            overrides && overrides.hasOwnProperty('guest_description')
                ? overrides.guest_description!
                : [aPrismicTextField()],
    };
};

export const aPrismicSampleAuthBannerTitle = (
    overrides?: Partial<PrismicSampleAuthBannerTitle>,
): {
    __typename: 'PrismicSampleAuthBannerTitle';
} & PrismicSampleAuthBannerTitle => {
    return {
        __typename: 'PrismicSampleAuthBannerTitle',
        authenticated_title:
            overrides && overrides.hasOwnProperty('authenticated_title')
                ? overrides.authenticated_title!
                : [aPrismicTextField()],
        guest_title:
            overrides && overrides.hasOwnProperty('guest_title')
                ? overrides.guest_title!
                : [aPrismicTextField()],
    };
};

export const aPrismicSampleBannerData = (
    overrides?: Partial<PrismicSampleBannerData>,
): { __typename: 'PrismicSampleBannerData' } & PrismicSampleBannerData => {
    return {
        __typename: 'PrismicSampleBannerData',
        background_color:
            overrides && overrides.hasOwnProperty('background_color')
                ? overrides.background_color!
                : 'minima',
        background_image:
            overrides && overrides.hasOwnProperty('background_image')
                ? overrides.background_image!
                : aPrismicBackgroundImage(),
        banner_title:
            overrides && overrides.hasOwnProperty('banner_title')
                ? overrides.banner_title!
                : [aPrismicTextField()],
        cta_path:
            overrides && overrides.hasOwnProperty('cta_path')
                ? overrides.cta_path!
                : [aPrismicTextField()],
        cta_text:
            overrides && overrides.hasOwnProperty('cta_text')
                ? overrides.cta_text!
                : [aPrismicTextField()],
        description:
            overrides && overrides.hasOwnProperty('description')
                ? overrides.description!
                : [aPrismicTextField()],
        title:
            overrides && overrides.hasOwnProperty('title')
                ? overrides.title!
                : [aPrismicTextField()],
    };
};

export const aPrismicSampleBannerGroupData = (
    overrides?: Partial<PrismicSampleBannerGroupData>,
): {
    __typename: 'PrismicSampleBannerGroupData';
} & PrismicSampleBannerGroupData => {
    return {
        __typename: 'PrismicSampleBannerGroupData',
        banner_a:
            overrides && overrides.hasOwnProperty('banner_a')
                ? overrides.banner_a!
                : aPrismicBanner(),
        banner_b:
            overrides && overrides.hasOwnProperty('banner_b')
                ? overrides.banner_b!
                : aPrismicBanner(),
        banner_c:
            overrides && overrides.hasOwnProperty('banner_c')
                ? overrides.banner_c!
                : aPrismicBanner(),
        banner_d:
            overrides && overrides.hasOwnProperty('banner_d')
                ? overrides.banner_d!
                : aPrismicBanner(),
        banner_e:
            overrides && overrides.hasOwnProperty('banner_e')
                ? overrides.banner_e!
                : aPrismicBanner(),
        title:
            overrides && overrides.hasOwnProperty('title')
                ? overrides.title!
                : [aPrismicTextField()],
    };
};

export const aPrismicTextField = (
    overrides?: Partial<PrismicTextField>,
): { __typename: 'PrismicTextField' } & PrismicTextField => {
    return {
        __typename: 'PrismicTextField',
        spans:
            overrides && overrides.hasOwnProperty('spans')
                ? overrides.spans!
                : ['ut'],
        text:
            overrides && overrides.hasOwnProperty('text')
                ? overrides.text!
                : 'nam',
        type:
            overrides && overrides.hasOwnProperty('type')
                ? overrides.type!
                : 'error',
    };
};

export const aPromotionalCard = (
    overrides?: Partial<PromotionalCard>,
): { __typename: 'PromotionalCard' } & PromotionalCard => {
    return {
        __typename: 'PromotionalCard',
        backgroundColor:
            overrides && overrides.hasOwnProperty('backgroundColor')
                ? overrides.backgroundColor!
                : 'similique',
        ctaText:
            overrides && overrides.hasOwnProperty('ctaText')
                ? overrides.ctaText!
                : 'dolorem',
        ctaUrl:
            overrides && overrides.hasOwnProperty('ctaUrl')
                ? overrides.ctaUrl!
                : 'dolorem',
        description:
            overrides && overrides.hasOwnProperty('description')
                ? overrides.description!
                : 'quis',
        id:
            overrides && overrides.hasOwnProperty('id')
                ? overrides.id!
                : 'afcb7125-c93a-4635-ad85-3a3dc58bf98b',
        imageHoverUrl:
            overrides && overrides.hasOwnProperty('imageHoverUrl')
                ? overrides.imageHoverUrl!
                : 'aliquam',
        imageUrl:
            overrides && overrides.hasOwnProperty('imageUrl')
                ? overrides.imageUrl!
                : 'et',
        isPrioritized:
            overrides && overrides.hasOwnProperty('isPrioritized')
                ? overrides.isPrioritized!
                : false,
        title:
            overrides && overrides.hasOwnProperty('title')
                ? overrides.title!
                : 'laborum',
        type:
            overrides && overrides.hasOwnProperty('type')
                ? overrides.type!
                : PromotionalCardType.Creator,
    };
};

export const aPurchasableCreatorSample = (
    overrides?: Partial<PurchasableCreatorSample>,
): PurchasableCreatorSample => {
    return {
        gainDb:
            overrides && overrides.hasOwnProperty('gainDb')
                ? overrides.gainDb!
                : 7.06,
        id:
            overrides && overrides.hasOwnProperty('id')
                ? overrides.id!
                : 'praesentium',
        semitoneShift:
            overrides && overrides.hasOwnProperty('semitoneShift')
                ? overrides.semitoneShift!
                : 2.64,
        timeFactor:
            overrides && overrides.hasOwnProperty('timeFactor')
                ? overrides.timeFactor!
                : 1.14,
    };
};

export const aPurchasableCreatorSession = (
    overrides?: Partial<PurchasableCreatorSession>,
): PurchasableCreatorSession => {
    return {
        async:
            overrides && overrides.hasOwnProperty('async')
                ? overrides.async!
                : true,
        samples:
            overrides && overrides.hasOwnProperty('samples')
                ? overrides.samples!
                : [aPurchasableCreatorSample()],
        targetBpm:
            overrides && overrides.hasOwnProperty('targetBpm')
                ? overrides.targetBpm!
                : 3233,
        targetKey:
            overrides && overrides.hasOwnProperty('targetKey')
                ? overrides.targetKey!
                : 'iste',
        targetKeyQuality:
            overrides && overrides.hasOwnProperty('targetKeyQuality')
                ? overrides.targetKeyQuality!
                : 'id',
        targetKeyRoot:
            overrides && overrides.hasOwnProperty('targetKeyRoot')
                ? overrides.targetKeyRoot!
                : 'totam',
    };
};

export const aPurchaseResponse = (
    overrides?: Partial<PurchaseResponse>,
): { __typename: 'PurchaseResponse' } & PurchaseResponse => {
    return {
        __typename: 'PurchaseResponse',
        url:
            overrides && overrides.hasOwnProperty('url')
                ? overrides.url!
                : 'nihil',
    };
};

export const aQuery = (
    overrides?: Partial<Query>,
): { __typename: 'Query' } & Query => {
    return {
        __typename: 'Query',
        accountBalanceSummary:
            overrides && overrides.hasOwnProperty('accountBalanceSummary')
                ? overrides.accountBalanceSummary!
                : anAccountBalanceSummary(),
        artistBySlug:
            overrides && overrides.hasOwnProperty('artistBySlug')
                ? overrides.artistBySlug!
                : anArtist(),
        bannerGroup:
            overrides && overrides.hasOwnProperty('bannerGroup')
                ? overrides.bannerGroup!
                : [aPrismicDocument()],
        brandtypeBySlug:
            overrides && overrides.hasOwnProperty('brandtypeBySlug')
                ? overrides.brandtypeBySlug!
                : aBrandtype(),
        chartsTopLabels:
            overrides && overrides.hasOwnProperty('chartsTopLabels')
                ? overrides.chartsTopLabels!
                : aLabelsChart(),
        chartsTopLandrPacks:
            overrides && overrides.hasOwnProperty('chartsTopLandrPacks')
                ? overrides.chartsTopLandrPacks!
                : aRepacksChart(),
        chartsTopPacks:
            overrides && overrides.hasOwnProperty('chartsTopPacks')
                ? overrides.chartsTopPacks!
                : aPacksChart(),
        collectionCostPreview:
            overrides && overrides.hasOwnProperty('collectionCostPreview')
                ? overrides.collectionCostPreview!
                : aCostPreviewResponse(),
        collectionPurchase:
            overrides && overrides.hasOwnProperty('collectionPurchase')
                ? overrides.collectionPurchase!
                : aPurchaseResponse(),
        contentGroup:
            overrides && overrides.hasOwnProperty('contentGroup')
                ? overrides.contentGroup!
                : aContentGroup(),
        creatorMixdownPurchase:
            overrides && overrides.hasOwnProperty('creatorMixdownPurchase')
                ? overrides.creatorMixdownPurchase!
                : aPurchaseResponse(),
        creatorSamplesCostPreview:
            overrides && overrides.hasOwnProperty('creatorSamplesCostPreview')
                ? overrides.creatorSamplesCostPreview!
                : aCostPreviewResponse(),
        creatorSamplesPurchase:
            overrides && overrides.hasOwnProperty('creatorSamplesPurchase')
                ? overrides.creatorSamplesPurchase!
                : aPurchaseResponse(),
        creatorSessionById:
            overrides && overrides.hasOwnProperty('creatorSessionById')
                ? overrides.creatorSessionById!
                : aCreatorSession(),
        filters:
            overrides && overrides.hasOwnProperty('filters')
                ? overrides.filters!
                : aFilterCategories(),
        genres:
            overrides && overrides.hasOwnProperty('genres')
                ? overrides.genres!
                : [aGenre()],
        getUserRecentSearch:
            overrides && overrides.hasOwnProperty('getUserRecentSearch')
                ? overrides.getUserRecentSearch!
                : aUserRecentSearches(),
        heroBanners:
            overrides && overrides.hasOwnProperty('heroBanners')
                ? overrides.heroBanners!
                : [aHeroBanner()],
        instruments:
            overrides && overrides.hasOwnProperty('instruments')
                ? overrides.instruments!
                : [anInstrument()],
        labelBySlug:
            overrides && overrides.hasOwnProperty('labelBySlug')
                ? overrides.labelBySlug!
                : aLabel(),
        lastCreatorSession:
            overrides && overrides.hasOwnProperty('lastCreatorSession')
                ? overrides.lastCreatorSession!
                : aCreatorSession(),
        merchZone:
            overrides && overrides.hasOwnProperty('merchZone')
                ? overrides.merchZone!
                : aMerchZone(),
        milestones:
            overrides && overrides.hasOwnProperty('milestones')
                ? overrides.milestones!
                : ['labore'],
        pack:
            overrides && overrides.hasOwnProperty('pack')
                ? overrides.pack!
                : aPack(),
        packCostPreview:
            overrides && overrides.hasOwnProperty('packCostPreview')
                ? overrides.packCostPreview!
                : aCostPreviewResponse(),
        packPurchase:
            overrides && overrides.hasOwnProperty('packPurchase')
                ? overrides.packPurchase!
                : aPurchaseResponse(),
        packStream:
            overrides && overrides.hasOwnProperty('packStream')
                ? overrides.packStream!
                : aStreamUrl(),
        paginatedArtistPacks:
            overrides && overrides.hasOwnProperty('paginatedArtistPacks')
                ? overrides.paginatedArtistPacks!
                : aPaginatedPacks(),
        paginatedArtistSamples:
            overrides && overrides.hasOwnProperty('paginatedArtistSamples')
                ? overrides.paginatedArtistSamples!
                : aPaginatedSamples(),
        paginatedArtists:
            overrides && overrides.hasOwnProperty('paginatedArtists')
                ? overrides.paginatedArtists!
                : aPaginatedArtists(),
        paginatedBrandtypePacks:
            overrides && overrides.hasOwnProperty('paginatedBrandtypePacks')
                ? overrides.paginatedBrandtypePacks!
                : aPaginatedPacks(),
        paginatedBrandtypeRepacks:
            overrides && overrides.hasOwnProperty('paginatedBrandtypeRepacks')
                ? overrides.paginatedBrandtypeRepacks!
                : aPaginatedRepacks(),
        paginatedBrandtypeSamples:
            overrides && overrides.hasOwnProperty('paginatedBrandtypeSamples')
                ? overrides.paginatedBrandtypeSamples!
                : aPaginatedSamples(),
        paginatedDownloadedPacksAndRepacksV2:
            overrides &&
            overrides.hasOwnProperty('paginatedDownloadedPacksAndRepacksV2')
                ? overrides.paginatedDownloadedPacksAndRepacksV2!
                : aPaginatedPacksOrRepacks(),
        paginatedDownloadedSamples:
            overrides && overrides.hasOwnProperty('paginatedDownloadedSamples')
                ? overrides.paginatedDownloadedSamples!
                : aPaginatedSamples(),
        paginatedFavoritedPacksAndRepacksV2:
            overrides &&
            overrides.hasOwnProperty('paginatedFavoritedPacksAndRepacksV2')
                ? overrides.paginatedFavoritedPacksAndRepacksV2!
                : aPaginatedPacksOrRepacks(),
        paginatedFavoritedSamples:
            overrides && overrides.hasOwnProperty('paginatedFavoritedSamples')
                ? overrides.paginatedFavoritedSamples!
                : aPaginatedSamples(),
        paginatedLabelPacks:
            overrides && overrides.hasOwnProperty('paginatedLabelPacks')
                ? overrides.paginatedLabelPacks!
                : aPaginatedPacks(),
        paginatedLabelSamples:
            overrides && overrides.hasOwnProperty('paginatedLabelSamples')
                ? overrides.paginatedLabelSamples!
                : aPaginatedSamples(),
        paginatedLabels:
            overrides && overrides.hasOwnProperty('paginatedLabels')
                ? overrides.paginatedLabels!
                : aPaginatedLabels(),
        paginatedPackSamples:
            overrides && overrides.hasOwnProperty('paginatedPackSamples')
                ? overrides.paginatedPackSamples!
                : aPaginatedSamples(),
        paginatedPacks:
            overrides && overrides.hasOwnProperty('paginatedPacks')
                ? overrides.paginatedPacks!
                : aPaginatedPacks(),
        paginatedPacksAndRepacks:
            overrides && overrides.hasOwnProperty('paginatedPacksAndRepacks')
                ? overrides.paginatedPacksAndRepacks!
                : aPaginatedPacksOrRepacks(),
        paginatedRecommendedSamples:
            overrides && overrides.hasOwnProperty('paginatedRecommendedSamples')
                ? overrides.paginatedRecommendedSamples!
                : aPaginatedSamples(),
        paginatedRepackSamples:
            overrides && overrides.hasOwnProperty('paginatedRepackSamples')
                ? overrides.paginatedRepackSamples!
                : aPaginatedSamples(),
        paginatedRepacks:
            overrides && overrides.hasOwnProperty('paginatedRepacks')
                ? overrides.paginatedRepacks!
                : aPaginatedRepacks(),
        paginatedSamples:
            overrides && overrides.hasOwnProperty('paginatedSamples')
                ? overrides.paginatedSamples!
                : aPaginatedSamples(),
        paginatedUserCollectionSamples:
            overrides &&
            overrides.hasOwnProperty('paginatedUserCollectionSamples')
                ? overrides.paginatedUserCollectionSamples!
                : aPaginatedSamples(),
        paginatedUserCollections:
            overrides && overrides.hasOwnProperty('paginatedUserCollections')
                ? overrides.paginatedUserCollections!
                : aPaginatedUserCollections(),
        pluginPackages:
            overrides && overrides.hasOwnProperty('pluginPackages')
                ? overrides.pluginPackages!
                : aPluginPackages(),
        promotionalCardsRepeater:
            overrides && overrides.hasOwnProperty('promotionalCardsRepeater')
                ? overrides.promotionalCardsRepeater!
                : [aPromotionalCard()],
        repack:
            overrides && overrides.hasOwnProperty('repack')
                ? overrides.repack!
                : aRepack(),
        repackCostPreview:
            overrides && overrides.hasOwnProperty('repackCostPreview')
                ? overrides.repackCostPreview!
                : aCostPreviewResponse(),
        repackPurchase:
            overrides && overrides.hasOwnProperty('repackPurchase')
                ? overrides.repackPurchase!
                : aPurchaseResponse(),
        repackStream:
            overrides && overrides.hasOwnProperty('repackStream')
                ? overrides.repackStream!
                : aStreamUrl(),
        sample:
            overrides && overrides.hasOwnProperty('sample')
                ? overrides.sample!
                : aSample(),
        sampleStream:
            overrides && overrides.hasOwnProperty('sampleStream')
                ? overrides.sampleStream!
                : aStreamUrl(),
        sampleWaveform:
            overrides && overrides.hasOwnProperty('sampleWaveform')
                ? overrides.sampleWaveform!
                : aWaveform(),
        samplesCostPreview:
            overrides && overrides.hasOwnProperty('samplesCostPreview')
                ? overrides.samplesCostPreview!
                : aCostPreviewResponse(),
        samplesPurchase:
            overrides && overrides.hasOwnProperty('samplesPurchase')
                ? overrides.samplesPurchase!
                : aPurchaseResponse(),
        seoByPath:
            overrides && overrides.hasOwnProperty('seoByPath')
                ? overrides.seoByPath!
                : aSeo(),
        seoByUrl:
            overrides && overrides.hasOwnProperty('seoByUrl')
                ? overrides.seoByUrl!
                : aSeo(),
        sfxs:
            overrides && overrides.hasOwnProperty('sfxs')
                ? overrides.sfxs!
                : [aSfx()],
        similarPacks:
            overrides && overrides.hasOwnProperty('similarPacks')
                ? overrides.similarPacks!
                : aPaginatedPacks(),
        subscriptionsAndAllowedFeatures:
            overrides &&
            overrides.hasOwnProperty('subscriptionsAndAllowedFeatures')
                ? overrides.subscriptionsAndAllowedFeatures!
                : aSubscriptionsAndAllowedFeatures(),
        tags:
            overrides && overrides.hasOwnProperty('tags')
                ? overrides.tags!
                : [aTag()],
        userAgreements:
            overrides && overrides.hasOwnProperty('userAgreements')
                ? overrides.userAgreements!
                : anAgreements(),
        userAllAccess:
            overrides && overrides.hasOwnProperty('userAllAccess')
                ? overrides.userAllAccess!
                : false,
        userAllSubscriptions:
            overrides && overrides.hasOwnProperty('userAllSubscriptions')
                ? overrides.userAllSubscriptions!
                : anAllSubscriptions(),
        userCollection:
            overrides && overrides.hasOwnProperty('userCollection')
                ? overrides.userCollection!
                : aUserCollection(),
        userCollections:
            overrides && overrides.hasOwnProperty('userCollections')
                ? overrides.userCollections!
                : [aUserCollection()],
        userCollectionsFeed:
            overrides && overrides.hasOwnProperty('userCollectionsFeed')
                ? overrides.userCollectionsFeed!
                : aUserCollectionsFeed(),
        userCredits:
            overrides && overrides.hasOwnProperty('userCredits')
                ? overrides.userCredits!
                : aUserCredits(),
        userHasSubscription:
            overrides && overrides.hasOwnProperty('userHasSubscription')
                ? overrides.userHasSubscription!
                : false,
        userProfile:
            overrides && overrides.hasOwnProperty('userProfile')
                ? overrides.userProfile!
                : aUser(),
        userSamplesSubscription:
            overrides && overrides.hasOwnProperty('userSamplesSubscription')
                ? overrides.userSamplesSubscription!
                : aCurrentSubscription(),
    };
};

export const aRepack = (
    overrides?: Partial<Repack>,
): { __typename: 'Repack' } & Repack => {
    return {
        __typename: 'Repack',
        artist:
            overrides && overrides.hasOwnProperty('artist')
                ? overrides.artist!
                : anArtist(),
        artworkUrl:
            overrides && overrides.hasOwnProperty('artworkUrl')
                ? overrides.artworkUrl!
                : 'vel',
        backgroundColor:
            overrides && overrides.hasOwnProperty('backgroundColor')
                ? overrides.backgroundColor!
                : 'dolore',
        brandtype:
            overrides && overrides.hasOwnProperty('brandtype')
                ? overrides.brandtype!
                : aBrandtype(),
        creatorSession:
            overrides && overrides.hasOwnProperty('creatorSession')
                ? overrides.creatorSession!
                : '4942bbb9-6b2e-4b8f-a6e8-24c2eb2a9a15',
        dateCreated:
            overrides && overrides.hasOwnProperty('dateCreated')
                ? overrides.dateCreated!
                : 'voluptatem',
        datePublished:
            overrides && overrides.hasOwnProperty('datePublished')
                ? overrides.datePublished!
                : 'blanditiis',
        description:
            overrides && overrides.hasOwnProperty('description')
                ? overrides.description!
                : 'voluptatem',
        foregroundColor:
            overrides && overrides.hasOwnProperty('foregroundColor')
                ? overrides.foregroundColor!
                : 'numquam',
        genres:
            overrides && overrides.hasOwnProperty('genres')
                ? overrides.genres!
                : [aGenre()],
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : '2a2eca53-cc50-4a01-8c61-f43f73dd0b10',
        id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 9104,
        instrument:
            overrides && overrides.hasOwnProperty('instrument')
                ? overrides.instrument!
                : anInstrument(),
        isDownloaded:
            overrides && overrides.hasOwnProperty('isDownloaded')
                ? overrides.isDownloaded!
                : true,
        isExclusive:
            overrides && overrides.hasOwnProperty('isExclusive')
                ? overrides.isExclusive!
                : false,
        isFavorited:
            overrides && overrides.hasOwnProperty('isFavorited')
                ? overrides.isFavorited!
                : true,
        isFree:
            overrides && overrides.hasOwnProperty('isFree')
                ? overrides.isFree!
                : false,
        label:
            overrides && overrides.hasOwnProperty('label')
                ? overrides.label!
                : aLabel(),
        mainGenre:
            overrides && overrides.hasOwnProperty('mainGenre')
                ? overrides.mainGenre!
                : aGenre(),
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'doloribus',
        packState:
            overrides && overrides.hasOwnProperty('packState')
                ? overrides.packState!
                : PackState.Draft,
        premiumCodes:
            overrides && overrides.hasOwnProperty('premiumCodes')
                ? overrides.premiumCodes!
                : [PremiumCodes.LandrStudio],
        sampleCount:
            overrides && overrides.hasOwnProperty('sampleCount')
                ? overrides.sampleCount!
                : 6861,
        seoMetadata:
            overrides && overrides.hasOwnProperty('seoMetadata')
                ? overrides.seoMetadata!
                : aSeoMetadata(),
        sfxs:
            overrides && overrides.hasOwnProperty('sfxs')
                ? overrides.sfxs!
                : [aSfx()],
        slug:
            overrides && overrides.hasOwnProperty('slug')
                ? overrides.slug!
                : 'qui',
        subType:
            overrides && overrides.hasOwnProperty('subType')
                ? overrides.subType!
                : RepackSubType.Chart,
        type:
            overrides && overrides.hasOwnProperty('type')
                ? overrides.type!
                : PackType.Pack,
    };
};

export const aRepackFavoritedInput = (
    overrides?: Partial<RepackFavoritedInput>,
): RepackFavoritedInput => {
    return {
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : '9da7a2b6-3711-4edd-a945-62309627a71f',
        isFavorited:
            overrides && overrides.hasOwnProperty('isFavorited')
                ? overrides.isFavorited!
                : false,
    };
};

export const aRepacksChart = (
    overrides?: Partial<RepacksChart>,
): { __typename: 'RepacksChart' } & RepacksChart => {
    return {
        __typename: 'RepacksChart',
        items:
            overrides && overrides.hasOwnProperty('items')
                ? overrides.items!
                : [aPacksChartItem()],
    };
};

export const aRepacksChartItem = (
    overrides?: Partial<RepacksChartItem>,
): { __typename: 'RepacksChartItem' } & RepacksChartItem => {
    return {
        __typename: 'RepacksChartItem',
        artistName:
            overrides && overrides.hasOwnProperty('artistName')
                ? overrides.artistName!
                : 'quasi',
        artworkUrl:
            overrides && overrides.hasOwnProperty('artworkUrl')
                ? overrides.artworkUrl!
                : 'aut',
        currentRank:
            overrides && overrides.hasOwnProperty('currentRank')
                ? overrides.currentRank!
                : 6301,
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : '74dc1a78-a54d-4e3d-83f4-e4da72198ee7',
        isNew:
            overrides && overrides.hasOwnProperty('isNew')
                ? overrides.isNew!
                : true,
        lastRank:
            overrides && overrides.hasOwnProperty('lastRank')
                ? overrides.lastRank!
                : 4675,
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'officia',
        slug:
            overrides && overrides.hasOwnProperty('slug')
                ? overrides.slug!
                : 'omnis',
        subItemsCount:
            overrides && overrides.hasOwnProperty('subItemsCount')
                ? overrides.subItemsCount!
                : 5019,
    };
};

export const aSample = (
    overrides?: Partial<Sample>,
): { __typename: 'Sample' } & Sample => {
    return {
        __typename: 'Sample',
        bpm:
            overrides && overrides.hasOwnProperty('bpm')
                ? overrides.bpm!
                : 5551,
        credits:
            overrides && overrides.hasOwnProperty('credits')
                ? overrides.credits!
                : 7022,
        descriptors:
            overrides && overrides.hasOwnProperty('descriptors')
                ? overrides.descriptors!
                : [aDescriptor()],
        duration:
            overrides && overrides.hasOwnProperty('duration')
                ? overrides.duration!
                : 3.32,
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : '1595e64f-60b4-4fc1-a26f-4d69582d9938',
        id:
            overrides && overrides.hasOwnProperty('id')
                ? overrides.id!
                : '8edbf799-cc95-469d-9ae3-42fef9c4a03f',
        instruments:
            overrides && overrides.hasOwnProperty('instruments')
                ? overrides.instruments!
                : [anInstrument()],
        isDownloaded:
            overrides && overrides.hasOwnProperty('isDownloaded')
                ? overrides.isDownloaded!
                : true,
        isFavorited:
            overrides && overrides.hasOwnProperty('isFavorited')
                ? overrides.isFavorited!
                : false,
        isRecommendationFiller:
            overrides && overrides.hasOwnProperty('isRecommendationFiller')
                ? overrides.isRecommendationFiller!
                : true,
        key:
            overrides && overrides.hasOwnProperty('key')
                ? overrides.key!
                : aSampleKey(),
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'neque',
        pack:
            overrides && overrides.hasOwnProperty('pack')
                ? overrides.pack!
                : aPack(),
        sampleState:
            overrides && overrides.hasOwnProperty('sampleState')
                ? overrides.sampleState!
                : SampleState.Available,
        sampleType:
            overrides && overrides.hasOwnProperty('sampleType')
                ? overrides.sampleType!
                : SampleType.Loop,
        sfxs:
            overrides && overrides.hasOwnProperty('sfxs')
                ? overrides.sfxs!
                : [aSfx()],
        tags:
            overrides && overrides.hasOwnProperty('tags')
                ? overrides.tags!
                : [aTag()],
        waveformUrl:
            overrides && overrides.hasOwnProperty('waveformUrl')
                ? overrides.waveformUrl!
                : 'sit',
    };
};

export const aSampleFavoritedInput = (
    overrides?: Partial<SampleFavoritedInput>,
): SampleFavoritedInput => {
    return {
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : 'e9dda05b-b8c7-4a1e-aa16-c7b34ece2e14',
        isFavorited:
            overrides && overrides.hasOwnProperty('isFavorited')
                ? overrides.isFavorited!
                : false,
    };
};

export const aSampleKey = (
    overrides?: Partial<SampleKey>,
): { __typename: 'SampleKey' } & SampleKey => {
    return {
        __typename: 'SampleKey',
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'distinctio',
        quality:
            overrides && overrides.hasOwnProperty('quality')
                ? overrides.quality!
                : KeyQuality.Major,
        root:
            overrides && overrides.hasOwnProperty('root')
                ? overrides.root!
                : KeyRootApi.A,
        sanitizedCode:
            overrides && overrides.hasOwnProperty('sanitizedCode')
                ? overrides.sanitizedCode!
                : 'libero',
    };
};

export const aSampleKeyInput = (
    overrides?: Partial<SampleKeyInput>,
): SampleKeyInput => {
    return {
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'dolorum',
        quality:
            overrides && overrides.hasOwnProperty('quality')
                ? overrides.quality!
                : KeyQuality.Major,
        root:
            overrides && overrides.hasOwnProperty('root')
                ? overrides.root!
                : KeyRootApi.A,
        sanitizedCode:
            overrides && overrides.hasOwnProperty('sanitizedCode')
                ? overrides.sanitizedCode!
                : 'dolore',
    };
};

export const aSampleTypeFilter = (
    overrides?: Partial<SampleTypeFilter>,
): { __typename: 'SampleTypeFilter' } & SampleTypeFilter => {
    return {
        __typename: 'SampleTypeFilter',
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'et',
    };
};

export const aSeo = (overrides?: Partial<Seo>): { __typename: 'Seo' } & Seo => {
    return {
        __typename: 'Seo',
        byline:
            overrides && overrides.hasOwnProperty('byline')
                ? overrides.byline!
                : 'aliquid',
        description:
            overrides && overrides.hasOwnProperty('description')
                ? overrides.description!
                : 'deleniti',
        header:
            overrides && overrides.hasOwnProperty('header')
                ? overrides.header!
                : 'recusandae',
        title:
            overrides && overrides.hasOwnProperty('title')
                ? overrides.title!
                : 'iure',
        url:
            overrides && overrides.hasOwnProperty('url')
                ? overrides.url!
                : 'alias',
    };
};

export const aSeoMetadata = (
    overrides?: Partial<SeoMetadata>,
): { __typename: 'SeoMetadata' } & SeoMetadata => {
    return {
        __typename: 'SeoMetadata',
        description:
            overrides && overrides.hasOwnProperty('description')
                ? overrides.description!
                : 'hic',
        title:
            overrides && overrides.hasOwnProperty('title')
                ? overrides.title!
                : 'aliquam',
    };
};

export const aSeoMetadataInput = (
    overrides?: Partial<SeoMetadataInput>,
): SeoMetadataInput => {
    return {
        description:
            overrides && overrides.hasOwnProperty('description')
                ? overrides.description!
                : 'qui',
        title:
            overrides && overrides.hasOwnProperty('title')
                ? overrides.title!
                : 'occaecati',
    };
};

export const aSfx = (overrides?: Partial<Sfx>): { __typename: 'Sfx' } & Sfx => {
    return {
        __typename: 'Sfx',
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'perferendis',
        subSfxs:
            overrides && overrides.hasOwnProperty('subSfxs')
                ? overrides.subSfxs!
                : [aSubItems()],
    };
};

export const aStreamUrl = (
    overrides?: Partial<StreamUrl>,
): { __typename: 'StreamUrl' } & StreamUrl => {
    return {
        __typename: 'StreamUrl',
        url:
            overrides && overrides.hasOwnProperty('url')
                ? overrides.url!
                : 'qui',
    };
};

export const aSubItems = (
    overrides?: Partial<SubItems>,
): { __typename: 'SubItems' } & SubItems => {
    return {
        __typename: 'SubItems',
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'enim',
    };
};

export const aSubscriptionPlanProductDetails = (
    overrides?: Partial<SubscriptionPlanProductDetails>,
): {
    __typename: 'SubscriptionPlanProductDetails';
} & SubscriptionPlanProductDetails => {
    return {
        __typename: 'SubscriptionPlanProductDetails',
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'sunt',
        currentQuantity:
            overrides && overrides.hasOwnProperty('currentQuantity')
                ? overrides.currentQuantity!
                : 6.39,
        endDate:
            overrides && overrides.hasOwnProperty('endDate')
                ? overrides.endDate!
                : 'reprehenderit',
        isUnlimited:
            overrides && overrides.hasOwnProperty('isUnlimited')
                ? overrides.isUnlimited!
                : false,
        maxQuantity:
            overrides && overrides.hasOwnProperty('maxQuantity')
                ? overrides.maxQuantity!
                : 2.84,
        numberOfCredits:
            overrides && overrides.hasOwnProperty('numberOfCredits')
                ? overrides.numberOfCredits!
                : 1348,
        status:
            overrides && overrides.hasOwnProperty('status')
                ? overrides.status!
                : SubscriptionProductStatus.Active,
    };
};

export const aSubscriptionsAndAllowedFeatures = (
    overrides?: Partial<SubscriptionsAndAllowedFeatures>,
): {
    __typename: 'SubscriptionsAndAllowedFeatures';
} & SubscriptionsAndAllowedFeatures => {
    return {
        __typename: 'SubscriptionsAndAllowedFeatures',
        hasSamplesPlan:
            overrides && overrides.hasOwnProperty('hasSamplesPlan')
                ? overrides.hasSamplesPlan!
                : true,
        hasStudioPlan:
            overrides && overrides.hasOwnProperty('hasStudioPlan')
                ? overrides.hasStudioPlan!
                : false,
        hasSubscription:
            overrides && overrides.hasOwnProperty('hasSubscription')
                ? overrides.hasSubscription!
                : false,
        samplePaidContentAccess:
            overrides && overrides.hasOwnProperty('samplePaidContentAccess')
                ? overrides.samplePaidContentAccess!
                : anAllowedFeature(),
    };
};

export const aTag = (overrides?: Partial<Tag>): { __typename: 'Tag' } & Tag => {
    return {
        __typename: 'Tag',
        code:
            overrides && overrides.hasOwnProperty('code')
                ? overrides.code!
                : 'sunt',
        displayName:
            overrides && overrides.hasOwnProperty('displayName')
                ? overrides.displayName!
                : 'aut',
    };
};

export const aTrack = (
    overrides?: Partial<Track>,
): { __typename: 'Track' } & Track => {
    return {
        __typename: 'Track',
        bpm:
            overrides && overrides.hasOwnProperty('bpm') ? overrides.bpm! : 834,
        bpmMultiplier:
            overrides && overrides.hasOwnProperty('bpmMultiplier')
                ? overrides.bpmMultiplier!
                : BpmMultiplier.Double,
        gain:
            overrides && overrides.hasOwnProperty('gain')
                ? overrides.gain!
                : 3988,
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : '08a6f787-61c1-4150-bb4b-94a122151ac2',
        pitchTranspose:
            overrides && overrides.hasOwnProperty('pitchTranspose')
                ? overrides.pitchTranspose!
                : 2910,
    };
};

export const aTrackInput = (overrides?: Partial<TrackInput>): TrackInput => {
    return {
        bpm:
            overrides && overrides.hasOwnProperty('bpm')
                ? overrides.bpm!
                : 8766,
        bpmMultiplier:
            overrides && overrides.hasOwnProperty('bpmMultiplier')
                ? overrides.bpmMultiplier!
                : BpmMultiplier.Double,
        gain:
            overrides && overrides.hasOwnProperty('gain')
                ? overrides.gain!
                : 4035,
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : 'e915eb64-1423-4103-810e-772257308577',
        pitchTranspose:
            overrides && overrides.hasOwnProperty('pitchTranspose')
                ? overrides.pitchTranspose!
                : 7898,
    };
};

export const anUnallowedReason = (
    overrides?: Partial<UnallowedReason>,
): { __typename: 'UnallowedReason' } & UnallowedReason => {
    return {
        __typename: 'UnallowedReason',
        reason:
            overrides && overrides.hasOwnProperty('reason')
                ? overrides.reason!
                : 'saepe',
        unallowedByPlan:
            overrides && overrides.hasOwnProperty('unallowedByPlan')
                ? overrides.unallowedByPlan!
                : 'consectetur',
    };
};

export const aUser = (
    overrides?: Partial<User>,
): { __typename: 'User' } & User => {
    return {
        __typename: 'User',
        country:
            overrides && overrides.hasOwnProperty('country')
                ? overrides.country!
                : 'nesciunt',
        creationTime:
            overrides && overrides.hasOwnProperty('creationTime')
                ? overrides.creationTime!
                : 'in',
        displayName:
            overrides && overrides.hasOwnProperty('displayName')
                ? overrides.displayName!
                : 'quos',
        email:
            overrides && overrides.hasOwnProperty('email')
                ? overrides.email!
                : 'sunt',
        experience:
            overrides && overrides.hasOwnProperty('experience')
                ? overrides.experience!
                : 'quo',
        firstname:
            overrides && overrides.hasOwnProperty('firstname')
                ? overrides.firstname!
                : 'aut',
        hasSeenArchiveNotification:
            overrides && overrides.hasOwnProperty('hasSeenArchiveNotification')
                ? overrides.hasSeenArchiveNotification!
                : false,
        homePage:
            overrides && overrides.hasOwnProperty('homePage')
                ? overrides.homePage!
                : HomePageEnum.Chromatic,
        isArchived:
            overrides && overrides.hasOwnProperty('isArchived')
                ? overrides.isArchived!
                : true,
        lastname:
            overrides && overrides.hasOwnProperty('lastname')
                ? overrides.lastname!
                : 'enim',
        musicGenres:
            overrides && overrides.hasOwnProperty('musicGenres')
                ? overrides.musicGenres!
                : [MusicGenre.Acoustic],
        permalink:
            overrides && overrides.hasOwnProperty('permalink')
                ? overrides.permalink!
                : 'ut',
        preferredCulture:
            overrides && overrides.hasOwnProperty('preferredCulture')
                ? overrides.preferredCulture!
                : 'dolor',
        primaryOccupation:
            overrides && overrides.hasOwnProperty('primaryOccupation')
                ? overrides.primaryOccupation!
                : OccupationEnum.AR,
        profilePicture72Url:
            overrides && overrides.hasOwnProperty('profilePicture72Url')
                ? overrides.profilePicture72Url!
                : 'doloremque',
        profilePicture360Url:
            overrides && overrides.hasOwnProperty('profilePicture360Url')
                ? overrides.profilePicture360Url!
                : 'molestiae',
        secondaryOccupations:
            overrides && overrides.hasOwnProperty('secondaryOccupations')
                ? overrides.secondaryOccupations!
                : [OccupationEnum.AR],
        signupCountry:
            overrides && overrides.hasOwnProperty('signupCountry')
                ? overrides.signupCountry!
                : 'dolor',
        signupCountryCode:
            overrides && overrides.hasOwnProperty('signupCountryCode')
                ? overrides.signupCountryCode!
                : 'ut',
        signupState:
            overrides && overrides.hasOwnProperty('signupState')
                ? overrides.signupState!
                : 'modi',
        userId:
            overrides && overrides.hasOwnProperty('userId')
                ? overrides.userId!
                : 'optio',
    };
};

export const aUserCollection = (
    overrides?: Partial<UserCollection>,
): { __typename: 'UserCollection' } & UserCollection => {
    return {
        __typename: 'UserCollection',
        artworkUrl:
            overrides && overrides.hasOwnProperty('artworkUrl')
                ? overrides.artworkUrl!
                : 'modi',
        dateCreated:
            overrides && overrides.hasOwnProperty('dateCreated')
                ? overrides.dateCreated!
                : 'aliquid',
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : 'e94b11e3-9607-4a98-bac0-b55965d08395',
        name:
            overrides && overrides.hasOwnProperty('name')
                ? overrides.name!
                : 'ducimus',
        sampleCount:
            overrides && overrides.hasOwnProperty('sampleCount')
                ? overrides.sampleCount!
                : 7280,
        seoMetadata:
            overrides && overrides.hasOwnProperty('seoMetadata')
                ? overrides.seoMetadata!
                : aSeoMetadata(),
        slug:
            overrides && overrides.hasOwnProperty('slug')
                ? overrides.slug!
                : 'vel',
        userId:
            overrides && overrides.hasOwnProperty('userId')
                ? overrides.userId!
                : 'caa99abc-98f1-4e4f-ac03-2e7c48780c5a',
    };
};

export const aUserCollectionsFeed = (
    overrides?: Partial<UserCollectionsFeed>,
): { __typename: 'UserCollectionsFeed' } & UserCollectionsFeed => {
    return {
        __typename: 'UserCollectionsFeed',
        feed:
            overrides && overrides.hasOwnProperty('feed')
                ? overrides.feed!
                : [aUserCollection()],
        meta:
            overrides && overrides.hasOwnProperty('meta')
                ? overrides.meta!
                : aMetaPage(),
    };
};

export const aUserCredits = (
    overrides?: Partial<UserCredits>,
): { __typename: 'UserCredits' } & UserCredits => {
    return {
        __typename: 'UserCredits',
        remaining:
            overrides && overrides.hasOwnProperty('remaining')
                ? overrides.remaining!
                : 9893,
    };
};

export const aUserRecentSearch = (
    overrides?: Partial<UserRecentSearch>,
): { __typename: 'UserRecentSearch' } & UserRecentSearch => {
    return {
        __typename: 'UserRecentSearch',
        filterSlug:
            overrides && overrides.hasOwnProperty('filterSlug')
                ? overrides.filterSlug!
                : 'id',
        searchFilter:
            overrides && overrides.hasOwnProperty('searchFilter')
                ? overrides.searchFilter!
                : UserSearchFilter.Descriptors,
    };
};

export const aUserRecentSearches = (
    overrides?: Partial<UserRecentSearches>,
): { __typename: 'UserRecentSearches' } & UserRecentSearches => {
    return {
        __typename: 'UserRecentSearches',
        recentSearch:
            overrides && overrides.hasOwnProperty('recentSearch')
                ? overrides.recentSearch!
                : [aUserRecentSearch()],
    };
};

export const aWaveform = (
    overrides?: Partial<Waveform>,
): { __typename: 'Waveform' } & Waveform => {
    return {
        __typename: 'Waveform',
        guid:
            overrides && overrides.hasOwnProperty('guid')
                ? overrides.guid!
                : 'bd98576b-b1b5-4f1b-a321-a82226e71a0b',
        waveform:
            overrides && overrides.hasOwnProperty('waveform')
                ? overrides.waveform!
                : [1.49],
    };
};
