import {
    ApolloCache,
    DefaultContext,
    FetchResult,
    MutationFunctionOptions,
    useMutation,
} from '@apollo/client';
import { useQueryWhenAuthenticated } from 'hooks/useQueryWhenAuthenticated';
import {
    PutMilestonesMutation,
    PutMilestonesMutationVariables,
} from 'types/generated/graphql';
import { Milestones, PutMilestones } from './milestones.gql';

export type PutUserMilestones = (
    options?:
        | MutationFunctionOptions<
              PutMilestonesMutation,
              PutMilestonesMutationVariables,
              DefaultContext,
              ApolloCache<any>
          >
        | undefined,
) => Promise<FetchResult<PutMilestonesMutation>>;

export const usePutUserMilestones = (): PutUserMilestones => {
    const [putUserMilestones] = useMutation<
        PutMilestonesMutation,
        PutMilestonesMutationVariables
    >(PutMilestones);

    return putUserMilestones;
};

export const useUserMilestones = (): {
    milestones: string[] | undefined;
    loading: boolean;
} => {
    const { data = { milestones: undefined }, loading } =
        useQueryWhenAuthenticated({
            query: Milestones,
            options: {
                fetchPolicy: 'no-cache',
            },
        });

    return { milestones: data.milestones, loading };
};
