import { APP_VERSION } from 'constants/app';
import { HttpLink } from '@apollo/client';
import { AnalyticsApplication } from '@landr/core';
import { CONFIG } from 'utils/config';

export const fetchWithInfo: typeof fetch = (uri, options) => {
    if (typeof uri !== 'string' || typeof options?.body !== 'string') {
        return fetch(uri, options);
    }

    const body = JSON.parse(options.body);
    const { operationName } =
        Array.isArray(body) && body.length > 0 ? body[0] : body;

    return fetch(`${uri}?operationName=${operationName}`, options);
};

const httpLink = new HttpLink({
    uri: CONFIG.VITE_GRAPHQL_API,
    fetch: fetchWithInfo,
    credentials: 'same-origin',
    headers: {
        'X-LANDR-CLIENT': `version=${APP_VERSION}, kind=${AnalyticsApplication.Samples}`,
    },
});

export const transportLink = httpLink;
